import { Card, CardContent, Grid } from '@mui/material';
import { getHrsBenefitsEligibilityClassCodes } from 'actions/hrsIntegrations/getHrsBenefitsEligibilityClassCodes';
import {
    AdpIntegrationStatuses,
    HrsIntegrationProviders,
    MajorMedicalWaitingPeriodIds,
    PaylocityIntegrationStatuses,
    TerminationDateDelays,
} from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import {
    EditPayrollIntegrationConfiguration,
    SetPayrollSystemDeduction,
} from 'api/generated/permissions';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import {
    MAJOR_MEDICAL_WAITING_PERIOD_ITEMS,
    majorMedicalWaitingPeriodIdNames,
} from 'constants/majorMedicalWaitingPeriods';
import {
    TERMINATION_DATE_DELAY_ITEMS,
    terminationDateDelayNames,
} from 'constants/terminationDateDelays';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import AdpIntegrationSection from 'pages/integrations/AdpIntegrationSection';
import FinchIntegrationSection from 'pages/integrations/FinchIntegrationSection';
import HrsBenefitsEligibilityClassCodeInfo from 'pages/integrations/HrsBenefitsEligibilityClassCodeInfo';
import HrsBenefitsEligibilityClassCodesModal from 'pages/integrations/HrsBenefitsEligibilityClassCodesModal';
import { IntegrationsLoadingContext } from 'pages/integrations/IntegrationsPage';
import PaylocityIntegrationSection from 'pages/integrations/PaylocityIntegrationSection';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { onChange } from 'utilities/forms';
import { enumToString, hasValue } from 'utilities/index';
import { number, string } from 'yup';

const majorMedicalWaitingPeriodOffsetMax = 92;
const deductionCodeMaxLength = 50;
const deductionCodeSchema = string()
    .max(deductionCodeMaxLength)
    .label('Deduction Code');
const inputNumberProps = {
    min: 0,
    placeholder: 'Enter a number',
    type: 'number',
};

const HrsIntegrationSection = ({
    save,
    team,
}: {
    save: ISaveEditableTextField<ITeam>;
    team: ITeam;
}) => {
    const dispatch = useThunkDispatch();
    const {
        hrsBenefitsEligibilityClassCodes,
        canEditHrsIntegrationConfiguration,
        canSetPayrollSystemDeduction,
    } = useSelector((state: AppStore) => ({
        canEditHrsIntegrationConfiguration: hasSomePermissions(
            state,
            EditPayrollIntegrationConfiguration
        ),
        canSetPayrollSystemDeduction: hasSomePermissions(state, SetPayrollSystemDeduction),
        hrsBenefitsEligibilityClassCodes: state.hrsBenefitsEligibilityClassCodes,
    }));
    const { loading, setLoading } = useContext(IntegrationsLoadingContext);
    const isAdpOrPaylocityIntegrationConnected =
        team.hrsIntegrationProvider === HrsIntegrationProviders.Adp
            ? team.adpIntegrationStatus === AdpIntegrationStatuses.Connected
            : team.paylocityIntegrationStatus === PaylocityIntegrationStatuses.Connected;
    const [majorMedicalWaitingPeriodId, setMajorMedicalWaitingPeriodId] = useState<
        MajorMedicalWaitingPeriodIds | undefined
    >(team.majorMedicalWaitingPeriodId);
    const [majorMedicalWaitingPeriodOffset, setMajorMedicalWaitingPeriodOffset] = useState<
        MajorMedicalWaitingPeriodIds | undefined
    >(team.majorMedicalWaitingPeriodOffset);
    const [terminationDateDelayId, setTerminationDateDelayId] = useState<
        TerminationDateDelays | undefined
    >(team.terminationDateDelayId);
    const [postTaxDeductionCode, setPostTaxDeductionCode] = useState(
        team.postTaxDeductionCode ?? ''
    );
    const [isHrisUpdateEmployeeSalary, setIsHrisUpdateEmployeeSalary] = useState<
        string | undefined
    >(team.isHrisUpdateEmployeeSalary?.toString());
    const [wageUpDeductionCode, setWageUpDeductionCode] = useState(team.wageUpDeductionCode ?? '');
    const [preTaxDeductionCode, setPreTaxDeductionCode] = useState(team.preTaxDeductionCode ?? '');
    const [TaxFreeReimbursementCode, setTaxFreeReimbursementCode] = useState(
        team.taxFreeReimbursementDeductionCode ?? ''
    );
    const [
        setWageUpAndWithholdingOnPayrollReportShare,
        setSetWageUpAndWithholdingOnPayrollReportShare,
    ] = useState(team.setWageUpAndWithholdingOnPayrollReportShare?.toString() ?? '');
    const {
        closeModal: closeBenefitsEligibilityClassCodesModal,
        isVisible: isBenefitsEligibilityClassCodesModalVisible,
        openModal: openBenefitsEligibilityClassCodesModalVisible,
    } = useModalState();
    const hrsProvider = team.hrsIntegrationProvider ?? HrsIntegrationProviders.NotConfigured;
    const hrsProviderName = enumToString(HrsIntegrationProviders, hrsProvider);
    const notConfigured = hrsProvider === HrsIntegrationProviders.NotConfigured;
    const isPaylocityOrAdp =
        team.hrsIntegrationProvider === HrsIntegrationProviders.Adp ||
        team.hrsIntegrationProvider === HrsIntegrationProviders.Paylocity;

    useEffect(() => {
        if (isAdpOrPaylocityIntegrationConnected) {
            dispatch(getHrsBenefitsEligibilityClassCodes(team.teamId, hrsProvider));
        }
    }, [dispatch, isAdpOrPaylocityIntegrationConnected, team.teamId, hrsProvider]);

    useEffect(() => {
        if ((notConfigured || isPaylocityOrAdp) && loading) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (notConfigured) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <Grid columnSpacing={4} container rowSpacing={3}>
                <Grid item xl={6} xs={12}>
                    <Card sx={{ marginBottom: { xs: 0 } }}>
                        <CardContent>
                            <DashboardCardHeader header="General" />
                            <hr />
                            <React.Fragment>
                                {canEditHrsIntegrationConfiguration && (
                                    <React.Fragment>
                                        <EditableSelectAttribute
                                            formatter={() =>
                                                hasValue(majorMedicalWaitingPeriodId)
                                                    ? majorMedicalWaitingPeriodIdNames[
                                                          majorMedicalWaitingPeriodId
                                                      ]
                                                    : 'Unknown'
                                            }
                                            infoTooltip="Amount of time after a member's start date in the payroll system that the active date will be set for new hires."
                                            items={MAJOR_MEDICAL_WAITING_PERIOD_ITEMS}
                                            label="Major Medical Waiting Period"
                                            name="majorMedicalWaitingPeriodId"
                                            onChange={onChange(setMajorMedicalWaitingPeriodId)}
                                            optionText="text"
                                            optionValue="value"
                                            save={save}
                                            validationSchema={string()
                                                .required()
                                                .label('Major Medical Waiting Period')}
                                            value={majorMedicalWaitingPeriodId}
                                        />
                                        {majorMedicalWaitingPeriodId?.toString() ===
                                            MajorMedicalWaitingPeriodIds.OffsetThenFirstDayOfNextMonth.toString() && (
                                            <EditableTextAttribute
                                                {...inputNumberProps}
                                                infoTooltip={
                                                    "When using the Offset Then First of Next Month Waiting Period, this sets the number of days from a workers hire date before setting the first of the next month. For example, if a worker starts on Jan 10th, and the offset is 30 days, the worker's active date will be 3/1"
                                                }
                                                isOptional
                                                label="Major Medical Waiting Offset"
                                                name="majorMedicalWaitingPeriodOffset"
                                                onChange={onChange(
                                                    setMajorMedicalWaitingPeriodOffset
                                                )}
                                                save={save}
                                                validationSchema={number()
                                                    .min(0)
                                                    .max(majorMedicalWaitingPeriodOffsetMax)
                                                    .label('Major Medical Waiting Period Offset')}
                                                value={majorMedicalWaitingPeriodOffset}
                                            />
                                        )}
                                        <EditableSelectAttribute
                                            formatter={() =>
                                                hasValue(terminationDateDelayId)
                                                    ? terminationDateDelayNames[
                                                          terminationDateDelayId
                                                      ]
                                                    : 'Unknown'
                                            }
                                            infoTooltip="Amount of time after the payroll system's termination date that the Termination Date will be set to."
                                            items={TERMINATION_DATE_DELAY_ITEMS}
                                            label="Termination Date Delay"
                                            name="terminationDateDelayId"
                                            onChange={onChange(setTerminationDateDelayId)}
                                            optionText="text"
                                            optionValue="value"
                                            save={save}
                                            validationSchema={string()
                                                .required()
                                                .label('Termination Date Delay')}
                                            value={terminationDateDelayId}
                                        />
                                        <EditableYesNoSelectAttribute
                                            infoTooltip="If set to no, the HRIS integration will not update employee salary."
                                            label="Update Employee Salary"
                                            name="isHrisUpdateEmployeeSalary"
                                            onChange={onChange(setIsHrisUpdateEmployeeSalary)}
                                            optionText="text"
                                            optionValue="value"
                                            save={save}
                                            validationSchema={string()
                                                .required()
                                                .label('HRIS Update Employee Salary')}
                                            value={isHrisUpdateEmployeeSalary}
                                        />
                                    </React.Fragment>
                                )}
                                {canSetPayrollSystemDeduction && (
                                    <React.Fragment>
                                        <EditableTextAttribute
                                            infoTooltip={`The deduction code in ${hrsProviderName} that will be used for withholdings for post-tax major medical plan costs. This corresponds with the Post-Tax Withholding section of the Payroll Report. This should be configured as a post-tax code in ${hrsProviderName}.`}
                                            label="Post-tax Deduction Code"
                                            name="postTaxDeductionCode"
                                            onChange={onChange(setPostTaxDeductionCode)}
                                            save={save}
                                            validationSchema={deductionCodeSchema}
                                            value={postTaxDeductionCode}
                                        />
                                        <EditableTextAttribute
                                            infoTooltip={`The deduction code in ${hrsProviderName} that will be used for withholding for pre-tax major medical plan costs. This corresponds with the Pre-Tax Withholding section of the Payoll Report. This should be configured as a pre-tax code in ${hrsProviderName}.`}
                                            label="Pre-tax Deduction Code"
                                            name="preTaxDeductionCode"
                                            onChange={onChange(setPreTaxDeductionCode)}
                                            save={save}
                                            validationSchema={deductionCodeSchema}
                                            value={preTaxDeductionCode}
                                        />
                                        {team.hrsIntegrationProvider ===
                                            HrsIntegrationProviders.Adp && (
                                            <EditableTextAttribute
                                                infoTooltip={`The deduction code in ${hrsProviderName} that will be used for Wage+ amounts. This corresponds with the Wage+ section of the Payroll Report. This should be configured as a pre-tax code in ${hrsProviderName} and accept negative values.`}
                                                label="Wage+ Deduction Code"
                                                name="wageUpDeductionCode"
                                                onChange={onChange(setWageUpDeductionCode)}
                                                save={save}
                                                validationSchema={deductionCodeSchema}
                                                value={wageUpDeductionCode}
                                            />
                                        )}
                                        {team.hrsIntegrationProvider !== HrsIntegrationProviders.Finch && (
                                            <EditableTextAttribute
                                                infoTooltip={`The deduction code in ${hrsProviderName} that will be used for Tax Free Reimbursements. This corresponds with the Tax-Free Reimbursement section of the Payroll Report. This should be configured to a post-tax code in ${hrsProviderName}.`}
                                                label="Tax Free Reimbursement Deduction Code"
                                                name="taxFreeReimbursementDeductionCode"
                                                onChange={onChange(setTaxFreeReimbursementCode)}
                                                save={save}
                                                validationSchema={deductionCodeSchema}
                                                value={TaxFreeReimbursementCode}
                                            />
                                        )}
                                        <EditableYesNoSelectAttribute
                                            infoTooltip="If Wage+ amounts and Withholdings will be set in the customer's payroll system when their Payroll Report is shared."
                                            label="Set Wage+ and Withholding on Payroll Report Share"
                                            name="setWageUpAndWithholdingOnPayrollReportShare"
                                            onChange={onChange(
                                                setSetWageUpAndWithholdingOnPayrollReportShare
                                            )}
                                            save={save}
                                            value={setWageUpAndWithholdingOnPayrollReportShare}
                                        />
                                        {isPaylocityOrAdp && (
                                            <EditableProfileAttribute
                                                content={
                                                    <HrsBenefitsEligibilityClassCodeInfo
                                                        data={hrsBenefitsEligibilityClassCodes}
                                                    />
                                                }
                                                infoTooltip={`Members that do not belong to one of these Benefits Eligibility Class Codes in ${hrsProviderName} will be excluded from the ${hrsProviderName} integration.`}
                                                label="Benefits Eligibility Class Codes"
                                                onClick={
                                                    openBenefitsEligibilityClassCodesModalVisible
                                                }
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={6} xs={12}>
                    {team.hrsIntegrationProvider === HrsIntegrationProviders.Adp && (
                        <AdpIntegrationSection save={save} />
                    )}
                    {team.hrsIntegrationProvider === HrsIntegrationProviders.Paylocity && (
                        <PaylocityIntegrationSection save={save} />
                    )}
                    {team.hrsIntegrationProvider === HrsIntegrationProviders.Finch && (
                        <FinchIntegrationSection save={save} />
                    )}
                </Grid>
            </Grid>
            {isBenefitsEligibilityClassCodesModalVisible && (
                <HrsBenefitsEligibilityClassCodesModal
                    classCodes={hrsBenefitsEligibilityClassCodes
                        .map((classCode) => classCode)
                        .filter(hasValue)}
                    hrsProvider={hrsProvider}
                    hrsProviderName={hrsProviderName}
                    onClose={closeBenefitsEligibilityClassCodesModal}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(HrsIntegrationSection);
