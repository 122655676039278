import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    SET_PAYROLL_DEDUCTIONS_ACTION,
    setPayrollDeductions,
} from 'actions/hrsIntegrations/setPayrollDeductions';
import {
    SET_PAYROLL_DEDUCTIONS_FOR_USER_ACTION,
    setPayrollDeductionsForUser,
} from 'actions/hrsIntegrations/setPayrollDeductionsForUser';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import { MONTHS } from 'constants/date';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatDateForApi, formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { DISPLAY_DATE_FORMAT } from 'utilities/moment';
import { object, string, TestConfig } from 'yup';
import { AnyObject } from 'yup/lib/object';
import Checkbox from 'components/Checkbox';
import { hasSomePermissions } from 'selectors/index';
import { SetDeductionsOverride } from 'api/generated/permissions';

const isInSameMonthOrPreviousArguments: TestConfig<string | undefined, AnyObject> = {
    message: 'Effective date must be in same or previous month of selected payroll report',
    name: 'isInSameMonthOrPrevious',
    test: (value, context) => {
        const effectiveYear = value?.getYear(DISPLAY_DATE_FORMAT);
        const effectiveMonth = value?.getMonth(DISPLAY_DATE_FORMAT);
        const reportYear = context.options.context?.['year'];
        const reportMonth = context.options.context?.['month'];
        const isValidIfJanuaryReport = reportMonth === 1 && effectiveMonth === 12;
        return (
            !hasValue(value) ||
            isValidIfJanuaryReport ||
            (effectiveYear === reportYear &&
                (effectiveMonth === reportMonth || effectiveMonth === reportMonth - 1))
        );
    },
};

const schema = object({
    effectiveDate: string()
        .isValidDate(false)
        .test(isInSameMonthOrPreviousArguments),
});

type IApplyDeductionsModal = {
    body?: string;
    close: () => void;
    month?: number;
    payrollReportId?: string;
    payrollReportUserSnapshotId?: string;
    year?: number;
};

const ApplyDeductionsModal = ({
    body,
    close,
    month,
    payrollReportId,
    payrollReportUserSnapshotId,
    year,
}: IApplyDeductionsModal) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            SET_PAYROLL_DEDUCTIONS_ACTION,
            SET_PAYROLL_DEDUCTIONS_FOR_USER_ACTION
        ),
    }));
    const [effectiveDate, setEffectiveDate] = useState('');
    const [overridePendingJob, setOverridePendingJob] = useState(false);
    const { errors, validate } = useForm(schema);

    const { canSetDeductionsOverride } = useSelector(
        (state: AppStore) => ({
            canSetDeductionsOverride: hasSomePermissions(state, SetDeductionsOverride),
        })
    );

    const toggleOverrideDeductions = () => {
        setOverridePendingJob(!overridePendingJob);
    };

    const onSubmit = async () => {
        const { isValid } = await validate({ effectiveDate }, { context: { month, year } });
        if (isValid) {
            if (hasValue(payrollReportUserSnapshotId)) {
                await dispatch(
                    setPayrollDeductionsForUser(
                        payrollReportId,
                        payrollReportUserSnapshotId,
                        formatDateForApi(effectiveDate),
                        overridePendingJob
                    )
                );
            } else {
                await dispatch(
                    setPayrollDeductions(payrollReportId, formatDateForApi(effectiveDate), overridePendingJob)
                );
            }
            close();
        }
    };

    const bodyContent =
        body ??
        `This will update deduction amounts in the team's payroll system for
        ${MONTHS[(month ?? 0) - 1]?.name}. Are you sure you want to apply
       deduction amounts for this payroll report?`;

    return (
        <Dialog fullWidth onClose={close} open>
            <DialogTitle>Apply Deductions</DialogTitle>
            <DialogContent dividers>
                <Form id="apply-deductions-modal-form" isLoading={isLoading} onSubmit={onSubmit}>
                    <Stack alignItems="center" gap={2}>
                        <Typography>{bodyContent}</Typography>
                        <DateTextField
                            autoFocus
                            errors={errors?.effectiveDate}
                            isOptional
                            label="Effective Date"
                            name="effectiveDate"
                            onChange={onChange(setEffectiveDate)}
                            value={formatDateForDisplay(effectiveDate)}
                        />
                    </Stack>
                    {
                        canSetDeductionsOverride &&
                        <Stack alignItems="flex-start">
                            <Checkbox
                                checked={overridePendingJob}
                                className="ml-0"
                                label="override pending job"
                                onClick={toggleOverrideDeductions}
                            />
                        </Stack>
                    }
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="text">
                    Cancel
                </Button>
                <LoadingButton
                    form="apply-deductions-modal-form"
                    loading={isLoading}
                    type="submit"
                    variant="text"
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default hot(module)(ApplyDeductionsModal);
