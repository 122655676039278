import { Close } from '@mui/icons-material';
import { Divider, IconButton, Link, Stack, SxProps, Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { IMarketplacePlanDto } from 'api/generated/models';
import Button from 'components/Button';
import MajorMedicalBenefitCostButton from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostButton';
import MoopReimbursementFooter from 'components/MoopReimbursementFooter';
import ReimbursementMyCostTooltip from 'components/ReimbursementMyCostTooltip';
import Typography from 'components/Typography';
import useUserProps from 'hooks/useUserProps';
import HsaEligibleChip from 'pages/shop/HsaEligibleChip';
import { ON_AND_OFF_EXCHANGE, ShoppingContext } from 'pages/shop/shopPageUtilities';
import { IShoppingPlan } from 'pages/shop/shopping';
import TaxSavingsChip from 'pages/shop/TaxSavingsChip';
import useShoppingConfiguration from 'pages/shop/useShoppingConfiguration';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';
import { hasValue } from 'utilities/index';
import { shouldShowRhReimbursement } from 'utilities/reimbursementOverlay';

const THREE = 3;
const REMAINING_WIDTH = 10;
const TWO_PLAN_WIDTH = 5;
const THREE_PLAN_WIDTH = REMAINING_WIDTH / THREE;

type IComparePlansContext = {
    cellBorder?: string;
    getPlanKey?: (plan?: IMarketplacePlanDto) => string;
    plans?: IMarketplacePlanDto[];
    rowHeight: number;
    rowWidth: number;
};
const ComparePlansContext = createContext<IComparePlansContext>({
    rowHeight: 45,
    rowWidth: 3.33,
});

const ComparePlansRow = ({
    alignTop,
    content,
    fontStyles = {},
    label,
    noBorder = false,
    styles = {},
}: {
    alignTop?: boolean;
    content?: JSX.Element[] | string[];
    fontStyles?: SxProps<Theme>;
    label: string;
    noBorder?: boolean;
    styles?: SxProps<Theme>;
}) => {
    const { cellBorder, getPlanKey, plans, rowHeight, rowWidth } = useContext(ComparePlansContext);
    return (
        <Grid
            alignItems={alignTop ? 'flex-start' : 'center'}
            container
            direction="row"
            height="100%"
            item
            justifyContent="space-between"
            minHeight={rowHeight}
            spacing={0}
            xs={12}
        >
            <Grid alignItems="center" item minHeight={rowHeight} xs={2}>
                <Typography sx={{ width: '100%' }} variant="h5">
                    {label}
                </Typography>
            </Grid>
            {content?.map((c, index) => (
                <Grid
                    alignItems="center"
                    container
                    height="100%"
                    item
                    justifyContent="center"
                    key={getPlanKey?.(plans?.[index])}
                    minHeight={rowHeight}
                    padding={1.5}
                    sx={noBorder ? { ...styles } : { border: cellBorder, ...styles }}
                    xs={rowWidth}
                >
                    <Typography sx={{ ...fontStyles }} textAlign="center" variant="body2">
                        {c}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

const ComparePlansModal = ({
    closeModal,
    selectPlan,
}: {
    closeModal: () => void;
    selectPlan: (plan: Partial<IShoppingPlan>) => Promise<void>;
}) => {
    const { shoppingComparePlans } = useContext(ShoppingContext);
    const config = useShoppingConfiguration();
    const { activeWageUp } = useUserProps();
    const isIchra = activeWageUp?.wasSetFromIchraPathway;

    const cellBorder = '1px solid #eee';
    const lightText = '#ccc';
    const rowHeight = 45;
    const rowWidth =
        (shoppingComparePlans?.length ?? THREE) === THREE ? THREE_PLAN_WIDTH : TWO_PLAN_WIDTH;

    const getPlanKey = useCallback(
        (plan?: IMarketplacePlanDto) => `${plan?.id}-${plan?.exchange ?? 'OffExchange'}`,
        []
    );
    const getYourCost = (plan?: IMarketplacePlanDto) => {
        if (isIchra) {
            return (
                <React.Fragment>
                    {formatCurrency(plan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost, {
                        preserveDecimal: false,
                    })}
                    <ReimbursementMyCostTooltip
                        memberCost={
                            plan?.majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost ?? 0
                        }
                        planCost={plan?.premium}
                        reimbursement={plan?.majorMedicalBenefitCost?.reimbursementAmount}
                    />
                </React.Fragment>
            );
        }
        return (
            <MajorMedicalBenefitCostButton majorMedicalBenefitCost={plan?.majorMedicalBenefitCost}>
                {formatCurrency(plan?.majorMedicalBenefitCost?.preTaxTotalMajorMedicalBenefitCost, {
                    preserveDecimal: true,
                })}
            </MajorMedicalBenefitCostButton>
        );
    };
    const showTaxSavings = (plan: IMarketplacePlanDto) =>
        config?.valueOf() === ON_AND_OFF_EXCHANGE && !hasValue(plan.exchange);

    const moopContent = useMemo(
        () =>
            shoppingComparePlans?.map((x) => {
                const showReimbursementOverlay = shouldShowRhReimbursement(
                    x.reimbursementOverlayDto
                );

                return (
                    <React.Fragment key={getPlanKey(x)}>
                        <span
                            style={
                                showReimbursementOverlay
                                    ? { color: lightText, textDecoration: 'line-through' }
                                    : {}
                            }
                        >
                            {formatCurrency(x.individualMoopCost)} per member
                            {hasValue(x.familyMoopCost) && (
                                <React.Fragment>
                                    <br />
                                    {formatCurrency(x.familyMoopCost)} per family
                                </React.Fragment>
                            )}
                        </span>
                        {showReimbursementOverlay && (
                            <MoopReimbursementFooter
                                familyMoopCost={x.familyMoopCost}
                                individualMoopCost={x.individualMoopCost}
                                planName={x.name}
                                reimbursementOverlayDto={x.reimbursementOverlayDto}
                            />
                        )}
                    </React.Fragment>
                );
            }),
        [getPlanKey, shoppingComparePlans]
    );

    const contextValue = useMemo(
        () => ({
            cellBorder,
            getPlanKey,
            rowHeight,
            rowWidth,
            plans: shoppingComparePlans,
        }),
        [cellBorder, getPlanKey, shoppingComparePlans, rowHeight, rowWidth]
    );

    return (
        <Dialog fullWidth maxWidth="lg" onClose={closeModal} open>
            <DialogContent>
                <Stack direction="row" justifyContent="space-between" marginBottom={2}>
                    <Typography variant="h4">Compare Plans</Typography>
                    <IconButton onClick={closeModal}>
                        <Close />
                    </IconButton>
                </Stack>
                <ComparePlansContext.Provider value={contextValue}>
                    <Grid container direction="column" spacing={2}>
                        <ComparePlansRow
                            alignTop
                            content={shoppingComparePlans?.map((x) => (
                                <Grid item key={getPlanKey(x)}>
                                    <span style={{ color: lightText, fontSize: '0.85rem' }}>
                                        {x.issuer?.name ?? ''}
                                    </span>
                                    <br />
                                    {x.name}
                                </Grid>
                            ))}
                            fontStyles={{
                                fontSize: '1rem',
                                fontWeight: 700,
                                paddingInline: 2,
                                textAlign: 'left',
                                width: '100%',
                            }}
                            label="Plan"
                            noBorder
                        />
                        {shoppingComparePlans?.some(
                            (p) => p.isHsaEligible || showTaxSavings(p)
                        ) && (
                            <ComparePlansRow
                                content={shoppingComparePlans?.map((plan) => (
                                    <React.Fragment key={getPlanKey(plan)}>
                                        {plan.isHsaEligible && (
                                            <Grid item paddingTop={0}>
                                                <HsaEligibleChip isHsaEligible />
                                            </Grid>
                                        )}
                                        {showTaxSavings(plan) && (
                                            <Grid item paddingTop={plan.isHsaEligible ? 1 : 0}>
                                                <TaxSavingsChip />
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                ))}
                                fontStyles={{
                                    paddingInline: 2,
                                    textAlign: 'left',
                                    width: '100%',
                                }}
                                label=" "
                                noBorder
                                styles={{ paddingBlock: 0 }}
                            />
                        )}
                        <Grid marginTop={3} xs={12}>
                            <Divider />
                        </Grid>
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((plan) => (
                                <Button
                                    color="primary"
                                    key={getPlanKey(plan)}
                                    onClick={async () => selectPlan(plan as Partial<IShoppingPlan>)}
                                    size="small"
                                    sx={{ maxWidth: 120 }}
                                    variant="contained"
                                >
                                    Select Plan
                                </Button>
                            ))}
                            label=" "
                            noBorder
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) =>
                                hasValue(x.benefitsUrl) ? (
                                    <Typography key={getPlanKey(x)} variant="body2">
                                        Review the{' '}
                                        <Link
                                            color="secondary"
                                            href={x.benefitsUrl}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Summary of Benefits
                                        </Link>{' '}
                                        for a full list of benefits, limits, and exclusions.
                                    </Typography>
                                ) : (
                                    <React.Fragment key={getPlanKey(x)} />
                                )
                            )}
                            fontStyles={{ fontSize: '0.65rem' }}
                            label=" "
                            noBorder
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => getYourCost(x))}
                            fontStyles={{ fontWeight: 600 }}
                            label="Your Monthly Cost"
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => x.metalLevel ?? '')}
                            label="Metal Level"
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => x.planType ?? '')}
                            label="Network Type"
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => (
                                <React.Fragment key={getPlanKey(x)}>
                                    {formatCurrency(x.individualDeductibleCost)} per member
                                    {hasValue(x.familyDeductibleCost) && (
                                        <React.Fragment>
                                            <br />
                                            {formatCurrency(x.familyDeductibleCost)} per family
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ))}
                            label="Deductible"
                        />
                        <ComparePlansRow content={moopContent} label="Max Out Of Pocket" />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => x.primaryCareCost ?? '')}
                            label="Primary Care Visit"
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => x.specialistCost ?? '')}
                            label="Specialist Visit"
                        />
                        <ComparePlansRow
                            content={shoppingComparePlans?.map((x) => x.genericDrugCost ?? '')}
                            label="Generic Drugs"
                        />
                    </Grid>
                </ComparePlansContext.Provider>
            </DialogContent>
        </Dialog>
    );
};

export default hot(module)(ComparePlansModal);
