import Autorenew from '@mui/icons-material/Autorenew';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { ADD_ICHRA_CLASS_ACTION } from 'actions/pathwayBlueprint/addIchraClass';
import { deleteIchraClass } from 'actions/pathwayBlueprint/deleteIchraClass';
import { EDIT_ICHRA_CLASS_ACTION } from 'actions/pathwayBlueprint/editIchraClass';
import { GET_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { TopLineReimbursementStrategies } from 'api/generated/enums';
import { IIchraClass, Pathway } from 'api/generated/models';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import {
    IchraClassModalContext,
    PathwayFilterContext,
} from 'pages/pathwayBlueprint/PathwayBlueprintPage';
import PathwayTable from 'pages/pathwayBlueprint/PathwayTable';
import React, { useContext, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, enumToNameValueArray, hasValue } from 'utilities/index';

const STRATEGY_OPTIONS = (enumToNameValueArray(TopLineReimbursementStrategies, {
    formatName: startCase,
    nameKey: 'label',
}) as unknown) as { label: string; value: TopLineReimbursementStrategies }[];

const IchraClassCard = ({
    ichraClass,
    pathways,
}: {
    ichraClass?: IIchraClass;
    pathways?: Pathway[];
}) => {
    const theme = useTheme();
    const dispatch = useThunkDispatch();
    const { openIchraClassModal, setIchraClassGlobalId } = useContext(IchraClassModalContext);
    const { filteredState, search } = useContext(PathwayFilterContext);
    const { isLoadingIchraClass, pathwayBlueprint, userProfiles } = useSelector(
        (state: AppStore) => ({
            isLoadingIchraClass: hasApiActivity(
                state,
                ADD_ICHRA_CLASS_ACTION,
                EDIT_ICHRA_CLASS_ACTION,
                GET_PATHWAY_BLUEPRINT_ACTION
            ),
            pathwayBlueprint: state.pathwayBlueprint,
            userProfiles: state.userProfiles,
        })
    );

    const isFiltered = hasValue(filteredState) || hasValue(search);
    const isCalculating = pathwayBlueprint.isCalculating;

    const ichraClassData = useMemo(() => {
        const pathwayIds = pathways?.map((p) => p.id);
        const pathwayUserIds = pathwayBlueprint.pathwayUsers
            ?.filter((p) => pathwayIds?.contains(p.pathwayId))
            .map((pu) => pu.userId);
        const ichraClassUserProfiles = userProfiles.filter((up) =>
            pathwayUserIds?.contains(up.user?.userId)
        );
        return ichraClassUserProfiles.map((icup) => ({
            displayName: icup.user?.displayName ?? '',
            state: icup?.address?.state ?? '',
        }));
    }, [pathwayBlueprint.pathwayUsers, pathways, userProfiles]);

    const hasMatch = hasValue(
        ichraClassData.find((icd) => {
            if (
                (hasValue(search) &&
                    !icd.displayName.toLowerCase().includes(search?.toLowerCase())) ||
                (hasValue(filteredState) && icd.state !== filteredState)
            ) {
                return false;
            }
            return true;
        })
    );

    const getItems = (globalId: string, isUnassigned: boolean) =>
        [
            {
                isDisabled: isCalculating,
                isLoading: isLoadingIchraClass,
                isVisible: true,
                onClick: () => handleEditClick(globalId),
                text: 'Edit',
            },
            {
                isConfirm: true,
                isLoading: isLoadingIchraClass,
                isVisible: isUnassigned,
                onClick: () => handleDeleteClick(globalId, pathwayBlueprint.id),
                text: 'Delete',
                variant: 'outline-danger',
            },
        ] as IActionButtonItems;

    const handleEditClick = (globalId: string) => {
        setIchraClassGlobalId?.(globalId);
        openIchraClassModal?.();
    };
    const handleDeleteClick = (globalId: string, pathwayBlueprintId: string) => {
        dispatch(deleteIchraClass(globalId, pathwayBlueprintId));
    };

    return !isFiltered || hasMatch ? (
        <Paper
            elevation={0}
            sx={{
                background: theme.palette.neutral.main,
                marginTop: 0,
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{ marginBottom: 3, width: '100%' }}
            >
                <Stack alignItems="center" direction="row" spacing={1}>
                    {ichraClass?.isStale && (
                        <Tooltip title="There has been a change to the ICHRA Top-Line Reimbursement Strategy and it has not been applied. Please go into the Class and apply the strategy.">
                            <Autorenew sx={{ color: () => theme.palette.orange.main }} />
                        </Tooltip>
                    )}
                    <Stack>
                        <Typography color="primary" marginBottom={0} variant="h4">
                            {ichraClass?.name}
                        </Typography>
                        <Typography color="primary" marginBottom={0} variant="body2">
                            {
                                STRATEGY_OPTIONS[
                                    ichraClass?.topLineReimbursementStrategyId as TopLineReimbursementStrategies
                                ]?.label
                            }{' '}
                            Strategy
                        </Typography>
                    </Stack>
                </Stack>
                <Grid item>
                    <ActionButtons
                        items={getItems(ichraClass?.globalId as string, !arrayHasValue(pathways))}
                    />
                </Grid>
            </Grid>
            <Stack gap={4}>
                {pathways?.map((p) => (
                    <PathwayTable key={p.id} pathway={p} />
                ))}
            </Stack>
        </Paper>
    ) : (
        <React.Fragment />
    );
};

export default hot(module)(IchraClassCard);
