import { ExpandMore } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Typography from 'components/Typography';
import useModalState from 'hooks/useModalState';
import ComparePlansModal from 'pages/shop/ComparePlansModal';
import { ON_AND_OFF_EXCHANGE, ShoppingContext } from 'pages/shop/shopPageUtilities';
import { IShoppingPlan } from 'pages/shop/shopping';
import TaxSavingsChip from 'pages/shop/TaxSavingsChip';
import useShoppingConfiguration from 'pages/shop/useShoppingConfiguration';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { arrayHasValue, hasValue } from 'utilities/index';

const TWO = 2;

const ComparePlans = ({
    selectPlan,
}: {
    selectPlan: (plan: Partial<IShoppingPlan>) => Promise<void>;
}) => {
    const theme = useTheme();
    const { setShoppingComparePlans, shoppingComparePlans } = useContext(ShoppingContext);
    const shoppingConfig = useShoppingConfiguration();
    const showTaxSavings = shoppingConfig?.valueOf() === ON_AND_OFF_EXCHANGE;
    const { closeModal, isVisible, openModal } = useModalState();

    return arrayHasValue(shoppingComparePlans) ? (
        <React.Fragment>
            <Accordion
                disableGutters
                sx={{
                    borderRadius: '4px',
                    bottom: 15,
                    left: { sm: 255, xs: 10 },
                    maxWidth: 300,
                    minWidth: 280,
                    position: 'absolute',
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: theme.palette.common.white }} />}
                    sx={{
                        background: theme.palette.secondary.main,
                        borderRadius: '4px',
                    }}
                >
                    <Stack direction="row" gap={2} justifyContent="space-between">
                        <Typography color={theme.palette.common.white} variant="h5">
                            Compare Plans ({shoppingComparePlans.length})
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={3}>
                        {shoppingComparePlans.map((plan, index) => (
                            <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="space-between"
                                key={`${plan.id}${plan.exchange ?? 'offExchange'}`}
                            >
                                <Typography variant="body2">
                                    <strong>Plan {index + 1}:</strong> {plan.issuer?.name} -{' '}
                                    {plan.name}{' '}
                                    {showTaxSavings && !hasValue(plan.exchange) && (
                                        <TaxSavingsChip />
                                    )}
                                </Typography>
                                <IconButton
                                    color="error"
                                    onClick={() => {
                                        setShoppingComparePlans?.(
                                            shoppingComparePlans?.filter(
                                                (x) =>
                                                    !(
                                                        x.id === plan.id &&
                                                        x.exchange === plan.exchange
                                                    )
                                            )
                                        );
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Stack>
                        ))}
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                            <Button
                                color="error"
                                onClick={() => {
                                    setShoppingComparePlans?.([]);
                                }}
                                variant="text"
                            >
                                Clear
                            </Button>
                            <ConditionalTooltip
                                isDisabled={shoppingComparePlans.length >= TWO}
                                title="Select at least 2 plans to compare"
                            >
                                <Button
                                    color="primary"
                                    disabled={shoppingComparePlans.length < TWO}
                                    onClick={() => openModal()}
                                    variant="contained"
                                >
                                    Compare
                                </Button>
                            </ConditionalTooltip>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            {isVisible && <ComparePlansModal closeModal={closeModal} selectPlan={selectPlan} />}
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
};

export default hot(module)(ComparePlans);
