import { Box, Divider, Typography } from '@mui/material';
import { getPathway } from 'actions/pathwayBlueprint/getPathway';
import { addSelectedPlan } from 'actions/selectedPlan/addSelectedPlan';
import { completeTask } from 'actions/taskFlows/completeTask';
import { PathwayTypes, PlanListing, PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import {
    IMarketplacePlanDto,
    IMediSharePlan,
    IPlanInfoDto,
    ISelectedPlan,
    ISelectedPlansDto,
} from 'api/generated/models';
import DropDownMenu from 'components/DropDownMenu';
import Skeleton from 'components/Skeleton';
import { push } from 'connected-react-router';
import { HEADER_FONT_COLOR } from 'constants/styleConstants';
import DesktopContext from 'contexts/DesktopContext';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { convertToPlanInfo } from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import ComparePlans from 'pages/shop/ComparePlans';
import ErrorPlanCard from 'pages/shop/ErrorPlanCard';
import HouseholdEligibility from 'pages/shop/HouseholdEligibility';
import PathwayPlanCard from 'pages/shop/PathwayPlanCard';
import PlanCard from 'pages/shop/PlanCard';
import ShopFilters, { BenefitsMarket, QUERY_PARAM } from 'pages/shop/ShopFilters';
import ShopHeader from 'pages/shop/ShopHeader';
import SpousePlanCard from 'pages/shop/SpousePlanCard';
import {
    IPlanType,
    ON_AND_OFF_EXCHANGE,
    SORT,
    ShoppingContext,
    getShowingMessage,
    shopPageSelector,
} from 'pages/shop/shopPageUtilities';
import { IShoppingPlan } from 'pages/shop/shopping';
import { IShoppingMemberSelection } from 'pages/shop/shoppingMemberSelectionUtilities';
import useShoppingConfiguration from 'pages/shop/useShoppingConfiguration';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { SELECTIONS_PATH, TEAMS_SELECTIONS_PATH } from 'routers/routes';
import { arrayHasValue, hasValue } from 'utilities/index';
import {
    buildSelectedMarketplacePlan,
    buildSelectedMediSharePlan,
    buildSelectedOtherCoverageFromPlanInputs,
} from 'utilities/selectedPlanBuilder';

const getPathwayContent = (
    handleSelectPlan: (plan?: Partial<IShoppingPlan>) => Promise<void>,
    isIchraFlowShopping: boolean,
    pathwayType: PathwayTypes,
    showTaxSavings: boolean,
    benefitsMarkets?: BenefitsMarket[]
): JSX.Element | '' => {
    let content: JSX.Element | '';
    switch (pathwayType) {
        case PathwayTypes.MarketplaceCappedEmployeeCost:
        case PathwayTypes.MarketplaceCappedEmployerCost:
        case PathwayTypes.IchraAgeBanded:
        case PathwayTypes.IchraCappedEmployeeCost:
        case PathwayTypes.IchraCappedEmployerCost:
            content =
                arrayHasValue(benefitsMarkets) &&
                benefitsMarkets.some((bm) =>
                    [BenefitsMarket.AcaMarketplace, BenefitsMarket.OffExchange].includes(bm)
                ) ? (
                    <PathwayPlanCard
                        handleSelectPlan={handleSelectPlan}
                        isIchraFlowShopping={isIchraFlowShopping}
                        pathwayType={pathwayType}
                        shouldShowTaxSavings={showTaxSavings}
                        showComparePlan
                    />
                ) : (
                    ''
                );
            break;
        case PathwayTypes.HealthcareSharingCappedEmployeeCost:
        case PathwayTypes.HealthcareSharingCappedEmployerCost:
            content = benefitsMarkets?.includes(BenefitsMarket.SharingPrograms) ? (
                <PathwayPlanCard handleSelectPlan={handleSelectPlan} pathwayType={pathwayType} />
            ) : (
                ''
            );
            break;
        default:
            content = '';
    }
    return content;
};

type IShopContent = {
    chunkPlan?: IPlanInfoDto;
    flowTaskId?: string;
    isIchraFlowShopping?: boolean;
    isTeamManagementPage?: boolean;
    showPreviousButton?: boolean;
    year: number;
};

const ShopContent = ({
    chunkPlan,
    flowTaskId,
    isIchraFlowShopping,
    isTeamManagementPage,
    showPreviousButton,
    year,
}: IShopContent) => {
    const dispatch = useThunkDispatch();
    const [query, setQuery] = useQuery();
    const isDesktop = useContext(DesktopContext);
    const { teamId, team } = useTeamProps();
    const { hasMemberVerifiedInfo, isCurrent, user, userId } = useUserProps();
    const shoppingConfig = useShoppingConfiguration();
    const {
        filteredPlans,
        hasMultipleRecommendedPlans,
        isPrimaryCovered,
        isSelectingPlan,
        isSomeMembersSelected,
        pathway,
        pathwayDataForUser,
        selectedHouseholdMembers,
    } = useSelector(shopPageSelector(undefined, query));
    const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>();
    const [benefitsMarkets, setBenefitsMarkets] = useState<BenefitsMarket[] | undefined>();
    const [excludedMembers, setExcludedMembers] = useState<string[] | undefined>();
    const [hasCompletedInitialLoading, setHasCompletedInitialLoading] = useState<boolean>(false);
    const [hasFetchedPlans, setHasFetchedPlans] = useState<boolean>(false);
    const [shoppingComparePlans, setShoppingComparePlans] = useState<
        IMarketplacePlanDto[] | undefined
    >([]);
    const [shoppingMemberSelections, setShoppingMemberSelections] = useState<
        IShoppingMemberSelection[]
    >();
    const [isLoadingPlanData, setIsLoadingPlanData] = useState<boolean>(false);
    const [isProvidersExpanded, setIsProvidersExpanded] = useState<boolean>(false);
    const showTaxSavings = shoppingConfig?.valueOf() === ON_AND_OFF_EXCHANGE;

    const sortPlans = useCallback(
        (index: string) => {
            query.set(QUERY_PARAM.SORT, index);
            setQuery(query);
        },
        [query, setQuery]
    );

    const sortMenuItems = useMemo(
        () => [
            {
                action: () => sortPlans(SORT.ASCENDING),
                label: 'Sorted by lowest cost',
            },
            {
                action: () => sortPlans(SORT.DESCENDING),
                label: 'Sorted by highest cost',
            },
        ],
        [sortPlans]
    );

    const handleIchraFlowSelectPlan = useCallback(
        async (plan) => {
            const marketplacePlan = plan as IMarketplacePlanDto;

            await dispatch(
                completeTask(
                    flowTaskId as string,
                    {
                        isWaived: false,
                        planInfoDtos: [
                            convertToPlanInfo(
                                selectedHouseholdMembers,
                                isPrimaryCovered,
                                marketplacePlan,
                                undefined,
                                year
                            ),
                        ],
                    } as ISelectedPlansDto
                )
            );
        },
        [dispatch, flowTaskId, selectedHouseholdMembers, isPrimaryCovered, year]
    );

    const handleSelectShoppingPlan = useCallback(
        async (plan) => {
            let selectedPlan: Partial<ISelectedPlan> = {};
            if (plan.isMediShare) {
                selectedPlan = buildSelectedMediSharePlan(plan);
            } else if (plan.isMarketplace) {
                selectedPlan = buildSelectedMarketplacePlan(
                    plan,
                    isEmpty((plan as IMarketplacePlanDto).exchange)
                        ? PlanTypeIds.OffExchange
                        : PlanTypeIds.Marketplace
                );
            } else if (plan.isSpousePlan) {
                selectedPlan = buildSelectedOtherCoverageFromPlanInputs(
                    {},
                    PlanTypeIds.SpouseEmployer
                );
            }
            selectedPlan.planStateId = PlanStateIds.Selected;
            selectedPlan.userId = userId;
            selectedPlan.isPrimaryCovered = isPrimaryCovered;
            selectedPlan.householdMembersCovered = selectedHouseholdMembers;
            selectedPlan.year = year;
            selectedPlan.coverageStartDate = user?.activeDate ?? team?.activeDate;
            if (selectedPlan.planTypeId !== PlanTypeIds.MediShare) {
                selectedPlan.coverageEndDate = moment(selectedPlan.coverageStartDate)
                    .endOf('year')
                    .format('YYYY-MM-DD hh:mm');
            }

            setSelectedPlanId(plan.planId);

            await dispatch(addSelectedPlan(selectedPlan as ISelectedPlan, isCurrent));
            dispatch(
                push(
                    isTeamManagementPage
                        ? generatePath(TEAMS_SELECTIONS_PATH, { teamId, userId })
                        : SELECTIONS_PATH
                )
            );
        },
        [
            dispatch,
            isCurrent,
            isPrimaryCovered,
            isTeamManagementPage,
            selectedHouseholdMembers,
            teamId,
            team?.activeDate,
            user?.activeDate,
            userId,
            year,
        ]
    );

    const handleSelectPlan = useCallback(
        async (plan?: Partial<IShoppingPlan>) => {
            if (hasValue(flowTaskId)) {
                handleIchraFlowSelectPlan(plan);
            } else {
                handleSelectShoppingPlan(plan);
            }
        },
        [flowTaskId, handleIchraFlowSelectPlan, handleSelectShoppingPlan]
    );

    const isPlanSelectionDisabled = useCallback(
        (plan: IPlanType) => {
            const planId = (plan as IMediSharePlan).ahp ?? (plan as IMarketplacePlanDto).id;
            return (
                (!isPrimaryCovered && isEmpty(selectedHouseholdMembers)) ||
                (planId !== selectedPlanId && isSelectingPlan)
            );
        },
        [isPrimaryCovered, isSelectingPlan, selectedHouseholdMembers, selectedPlanId]
    );

    const getIsSelectingPlan = useCallback(
        (plan: IPlanType) => {
            const planId = (plan as IMediSharePlan).ahp ?? (plan as IMarketplacePlanDto).id;
            return planId === selectedPlanId && isSelectingPlan;
        },
        [isSelectingPlan, selectedPlanId]
    );

    const individualOnly =
        (isPrimaryCovered && selectedHouseholdMembers?.length === 0) ||
        (!isPrimaryCovered && selectedHouseholdMembers?.length === 1);
    const allMembersDisabled = !isSomeMembersSelected && arrayHasValue(excludedMembers);
    const shouldShowShoppingContent = hasFetchedPlans || allMembersDisabled;

    const filteredPlanCards = useMemo(
        () =>
            filteredPlans
                .filter((x) => !x.hasError)
                .map((plan) => {
                    const marketplacePlan = plan as IMarketplacePlanDto;
                    const medisharePlan = plan as IMediSharePlan;
                    const isChunkPlan =
                        isIchraFlowShopping &&
                        marketplacePlan.id === chunkPlan?.planId &&
                        (hasValue(marketplacePlan.exchange)
                            ? chunkPlan?.planTypeId === PlanTypeIds.Marketplace
                            : chunkPlan?.planTypeId === PlanTypeIds.OffExchange);
                    const matchingPathwayPlan = hasMultipleRecommendedPlans
                        ? pathway.pathwayRecommendedPlans?.find(
                              (recommendedPlan) =>
                                  recommendedPlan.planId === marketplacePlan.id &&
                                  ((recommendedPlan.benchmarkType === PlanListing.OnExchange &&
                                      hasValue(marketplacePlan.exchange)) ||
                                      (recommendedPlan.benchmarkType === PlanListing.OffExchange &&
                                          !hasValue(marketplacePlan.exchange)))
                          )
                        : undefined;
                    return (
                        <PlanCard
                            handleSelectPlan={handleSelectPlan}
                            individualOnly={individualOnly}
                            isChunkPlan={isChunkPlan}
                            isDisabled={isPlanSelectionDisabled(plan)}
                            isIchraFlowShopping={isIchraFlowShopping}
                            isSelectingPlan={getIsSelectingPlan(plan)}
                            key={`${marketplacePlan.id ??
                                medisharePlan.ahp}-${marketplacePlan.exchange ?? ''}`}
                            plan={plan}
                            recommendedPlanDescription={matchingPathwayPlan?.description}
                            recommendedPlanLabel={matchingPathwayPlan?.planLabel}
                            showComparePlan
                            showRecommendedPlanBookmark={hasValue(matchingPathwayPlan)}
                            showTaxSavings={showTaxSavings}
                        />
                    );
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            chunkPlan,
            filteredPlans,
            getIsSelectingPlan,
            individualOnly,
            isIchraFlowShopping,
            isPlanSelectionDisabled,
            showTaxSavings,
        ]
    );

    const pathwayContent = useMemo(
        () =>
            getPathwayContent(
                handleSelectPlan,
                isIchraFlowShopping ?? false,
                pathwayDataForUser.pathwayType,
                showTaxSavings,
                benefitsMarkets
            ),
        [
            benefitsMarkets,
            handleSelectPlan,
            isIchraFlowShopping,
            pathwayDataForUser.pathwayType,
            showTaxSavings,
        ]
    );

    const shoppingContextValue = useMemo(
        () => ({
            benefitsMarkets,
            excludedMembers,
            hasCompletedInitialLoading,
            hasFetchedPlans,
            isLoadingPlanData,
            isProvidersExpanded,
            setBenefitsMarkets,
            setExcludedMembers,
            setHasCompletedInitialLoading,
            setHasFetchedPlans,
            setIsLoadingPlanData,
            setIsProvidersExpanded,
            setShoppingComparePlans,
            setShoppingMemberSelections,
            shoppingComparePlans,
            shoppingMemberSelections,
        }),
        [
            benefitsMarkets,
            excludedMembers,
            hasCompletedInitialLoading,
            hasFetchedPlans,
            isLoadingPlanData,
            isProvidersExpanded,
            shoppingComparePlans,
            shoppingMemberSelections,
        ]
    );

    let paddingClassName = isIchraFlowShopping ? '' : 'px-3';
    if (!isDesktop) {
        paddingClassName = 'px-1';
    }

    useEffect(() => {
        if (hasValue(pathwayDataForUser?.id)) {
            dispatch(getPathway(pathwayDataForUser.id));
        }
    }, [dispatch, pathwayDataForUser.id]);

    return (
        <ShoppingContext.Provider value={shoppingContextValue}>
            {hasMemberVerifiedInfo ? (
                <React.Fragment>
                    <ShopHeader
                        flowTaskId={flowTaskId}
                        isIchraFlowShopping={isIchraFlowShopping}
                        isTeamManagementPage={isTeamManagementPage}
                        showPreviousButton={showPreviousButton}
                    />
                    <Row className={paddingClassName}>
                        <Skeleton
                            count={4}
                            height={150}
                            isEnabled={!shouldShowShoppingContent}
                            structure={[
                                [
                                    {
                                        count: 5,
                                        height: 150,

                                        sx: { mt: 3 },
                                        width: 255,
                                    },
                                ],
                            ]}
                            width={255}
                        >
                            <Col
                                className="px-3 my-3 border-right"
                                sm="auto"
                                style={isDesktop ? { maxWidth: '255px' } : { maxWidth: '100%' }}
                            >
                                <Typography color={HEADER_FONT_COLOR} variant="h3">
                                    Shopping for {year}
                                </Typography>
                                {isSomeMembersSelected && (
                                    <React.Fragment>
                                        <hr />
                                        <ShopFilters />
                                    </React.Fragment>
                                )}
                            </Col>
                        </Skeleton>

                        <Col
                            className={paddingClassName + ' my-3'}
                            style={
                                isDesktop
                                    ? { maxWidth: 'calc(100% - 255px)' }
                                    : { maxWidth: '100%' }
                            }
                        >
                            <Skeleton
                                count={5}
                                isEnabled={!shouldShowShoppingContent}
                                structure={[
                                    [
                                        {
                                            columnProps: { flex: 1, gap: 2 },
                                            count: 5,
                                            height: 363,
                                            width: '100%',
                                        },
                                    ],
                                ]}
                            >
                                <React.Fragment>
                                    <HouseholdEligibility />
                                    {isSomeMembersSelected ? (
                                        <React.Fragment>
                                            {!hasMultipleRecommendedPlans && (
                                                <React.Fragment>{pathwayContent}</React.Fragment>
                                            )}
                                            <SpousePlanCard
                                                handleSelectPlan={handleSelectPlan}
                                                isPlanSelectionDisabled={isPlanSelectionDisabled}
                                            />
                                            <hr />
                                            <Row className="mx-2 mb-2 align-items-center justify-content-between">
                                                <span>
                                                    {arrayHasValue(benefitsMarkets) &&
                                                        getShowingMessage(
                                                            filteredPlans,
                                                            benefitsMarkets
                                                        )}
                                                </span>
                                                <DropDownMenu
                                                    data-cy="cost-sort-dropdown"
                                                    iconClass="mdi-menu-down"
                                                    items={sortMenuItems}
                                                    text={
                                                        sortMenuItems[
                                                            Number(
                                                                query.get(QUERY_PARAM.SORT) ??
                                                                    SORT.ASCENDING
                                                            )
                                                        ]?.label
                                                    }
                                                />
                                            </Row>
                                            <ErrorPlanCard />
                                            <React.Fragment>{filteredPlanCards}</React.Fragment>
                                        </React.Fragment>
                                    ) : (
                                        <Typography textAlign="center" variant="h3">
                                            Marketplace and Medi-Share plans are hidden because all
                                            household members may be eligible for other recommended
                                            options
                                        </Typography>
                                    )}
                                </React.Fragment>
                            </Skeleton>
                        </Col>
                    </Row>
                    <ComparePlans selectPlan={handleSelectPlan} />
                </React.Fragment>
            ) : (
                <Box maxWidth="700px" padding={3} sx={{ maxWidth: '700px' }}>
                    {hasCompletedInitialLoading && (
                        <React.Fragment>
                            <Typography variant="h4">
                                There is an issue with this member&apos;s profile
                            </Typography>
                            <Divider sx={{ marginBottom: 3 }} />
                            <Typography variant="body2">
                                {user?.firstName} does not have enough household information for{' '}
                                {year} to show plans in shopping.
                            </Typography>
                        </React.Fragment>
                    )}
                </Box>
            )}
        </ShoppingContext.Provider>
    );
};

export default hot(module)(ShopContent);
