import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import GroupIcon from '@mui/icons-material/Group';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { SET_PAYROLL_DEDUCTIONS_ACTION } from 'actions/hrsIntegrations/setPayrollDeductions';
import { deletePayrollReport } from 'actions/payroll/deletePayrollReport';
import { GET_PAYROLL_REPORTS_FOR_YEAR_ACTION } from 'actions/payroll/getPayrollReportsForYear';
import {
    REFRESH_PAYROLL_REPORT_ACTION,
    refreshPayrollReport,
} from 'actions/payroll/refreshPayrollReport';
import {
    SET_PAYROLL_REPORT_NOTE_ACTION,
    setPayrollReportNote,
} from 'actions/payroll/setPayrollReportNote';
import {
    TOGGLE_PAYROLL_REPORT_LOCKED_ACTION,
    togglePayrollReportLocked,
} from 'actions/payroll/togglePayrollReportLocked';
import {
    TOGGLE_PAYROLL_REPORT_SHARED_ACTION,
    togglePayrollReportShared,
} from 'actions/payroll/togglePayrollReportShared';
import { downloadChangeLog } from 'api/downloadChangeLog';
import { downloadExpenseSummary } from 'api/downloadExpenseSummary';
import { downloadPayrollReport } from 'api/downloadPayrollReport';
import { AdpIntegrationStatuses } from 'api/generated/enums';
import { IPayrollReportDto } from 'api/generated/models';
import {
    CanDownload1095CPart3,
    DeletePayrollReports,
    EditPayrollReports,
    ViewInvoiceSummary,
    ViewPayrollReports,
    ViewTeamSharedPayrollReports,
} from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IActionButtonItems } from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import TableHeader from 'components/TableHeader';
import TextAreaModal from 'components/TextAreaModal';
import Tooltip from 'components/Tooltip';
import { MONTH_MAX, MONTHS } from 'constants/date';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import upperFirst from 'lodash/upperFirst';
import ApplyDeductionsModal from 'pages/payrollReports/ApplyDeductionsModal';
import DeductionDeadlineDay from 'pages/payrollReports/DeductionDeadlineDay';
import Download1095CPart3ReportModal from 'pages/payrollReports/Download1095CPart3ReportModal';
import ExpenseSummaryModal from 'pages/payrollReports/ExpenseSummaryModal';
import Generate1095CPart2ReportModal from 'pages/payrollReports/Generate1095CPart2ReportModal';
import GeneratePayrollReportModal from 'pages/payrollReports/GeneratePayrollReportModal';
import InvoiceSummary from 'pages/payrollReports/InvoiceSummary';
import { setPayrollReportSelectedYear } from 'pages/payrollReports/payrollReportActions';
import PayrollReportChangeLogModal from 'pages/payrollReports/PayrollReportChangeLogModal';
import { useHrsForPayrollReport } from 'pages/payrollReports/useHrsForPayrollReport';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isInRoleSelector } from 'selectors/role';
import { hasContents, hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { downloadFileFromApi } from 'utilities/rhFile';
import { getYear, years } from 'utilities/year';

const NOTE_MAX_LENGTH = 1000;

const QUERY_PARAM = {
    MONTH: 'month',
    YEAR: 'year',
} as const;

const PayrollReportsPageHeader = ({
    activeReport,
    month,
    setMonth,
}: {
    activeReport: IPayrollReportDto | undefined;
    month: number;
    setMonth: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const dispatch = useThunkDispatch();
    const { adpIntegrationStatus, payrollReportSharingEnabled, team } = useTeamProps();
    const {
        canDeleteReport,
        canDownload1095CPart3,
        canEditReport,
        canViewInvoiceSummary,
        canViewReport,
        canViewTeamSharedPayrollReports,
        isLoading,
        isLoadingSetNote,
        isLoadingTogglePayrollReportLocked,
        isLoadingTogglePayrollReportShared,
        isRhTeamMember,
        selectedYear,
    } = useSelector((state: AppStore) => ({
        canDeleteReport: hasSomePermissions(state, DeletePayrollReports),
        canDownload1095CPart3: hasSomePermissions(state, CanDownload1095CPart3),
        canEditReport: hasSomePermissions(state, EditPayrollReports),
        canViewInvoiceSummary: hasSomePermissions(state, ViewInvoiceSummary),
        canViewReport: hasSomePermissions(state, ViewPayrollReports),
        canViewTeamSharedPayrollReports: hasSomePermissions(state, ViewTeamSharedPayrollReports),
        isLoading: hasApiActivity(
            state,
            GET_PAYROLL_REPORTS_FOR_YEAR_ACTION,
            REFRESH_PAYROLL_REPORT_ACTION
        ),
        isLoadingSetNote: hasApiActivity(state, SET_PAYROLL_REPORT_NOTE_ACTION),
        isLoadingSetPayrollDeductions: hasApiActivity(state, SET_PAYROLL_DEDUCTIONS_ACTION),
        isLoadingTogglePayrollReportLocked: hasApiActivity(
            state,
            TOGGLE_PAYROLL_REPORT_LOCKED_ACTION
        ),
        isLoadingTogglePayrollReportShared: hasApiActivity(
            state,
            TOGGLE_PAYROLL_REPORT_SHARED_ACTION
        ),
        isRhTeamMember: isInRoleSelector(state, ROLE_IDS.RH_TEAM_MEMBER),
        selectedYear: state.payrollReportState.payrollReportSelectedYear,
    }));

    const [query, setQuery] = useQuery();
    const [isDownloadingPayrollReport, setIsDownloadingPayrollReport] = useState(false);
    const [isDownloadingChangeLog, setIsDownloadingChangeLog] = useState(false);
    const [isDownloadingExpenseSummary, setIsDownloadingExpenseSummary] = useState(false);
    const {
        closeModal: closeGenerateReportModal,
        isVisible: isGenerateReportModalVisible,
        openModal: openGenerateReportModal,
    } = useModalState();
    const {
        closeModal: closeGenerate1095CPart2Modal,
        isVisible: isGenerate1095CPart2ModalVisible,
        openModal: openGenerate1095CPart2Modal,
    } = useModalState();
    const {
        closeModal: closeDownload1095CPart3Modal,
        isVisible: isDownload1095CPart3ModalVisible,
        openModal: openDownload1095CPart3Modal,
    } = useModalState();
    const {
        closeModal: closeRefreshReportModal,
        isVisible: isRefreshReportModalVisible,
        openModal: openRefreshReportModal,
    } = useModalState();
    const {
        closeModal: closeTextAreaModal,
        isVisible: isTextAreaModalVisible,
        openModal: openTextAreaModal,
    } = useModalState();
    const {
        closeModal: closeTogglePayrollReportLockedModal,
        isVisible: isTogglePayrollReportLockedModalVisible,
        openModal: openTogglePayrollReportLockedModal,
    } = useModalState();
    const {
        closeModal: closeTogglePayrollReportSharedModal,
        isVisible: isTogglePayrollReportSharedModalVisible,
        openModal: openTogglePayrollReportSharedModal,
    } = useModalState();
    const {
        closeModal: closeDeleteReportModal,
        isVisible: isDeleteReportModalVisible,
        openModal: openDeleteReportModal,
    } = useModalState();
    const {
        closeModal: closeChangeLogModal,
        isVisible: isChangeLogModalVisible,
        openModal: openChangeLogModal,
    } = useModalState();
    const {
        closeModal: closeApplyDeductionsModal,
        isVisible: isApplyDeductionsModalVisible,
        openModal: openApplyDeductionsModal,
    } = useModalState();
    const {
        closeModal: closeExpenseSummaryModal,
        isVisible: isExpenseSummaryModalVisible,
        openModal: openExpenseSummaryModal,
    } = useModalState();

    const setCurrentYear = (newYear: string) => {
        dispatch(setPayrollReportSelectedYear(newYear));
        query.set(QUERY_PARAM.YEAR, newYear);
        setQuery(query);
    };
    const setCurrentMonth = (newMonth: number | string) => {
        setMonth(Number(newMonth));
        query.set(QUERY_PARAM.MONTH, newMonth.toString());
        setQuery(query);
    };
    const closeModal = (newYear?: string, newMonth?: number) => {
        if (newYear !== undefined && newMonth !== undefined) {
            setCurrentYear(newYear);
            setCurrentMonth(newMonth);
        }
        closeGenerateReportModal();
    };

    const onDownloadPayrollReportClick = useCallback(async () => {
        setIsDownloadingPayrollReport(true);
        await downloadFileFromApi(async () => downloadPayrollReport(activeReport?.id));
        setIsDownloadingPayrollReport(false);
    }, [activeReport?.id]);

    const onDownloadChangeLogClick = useCallback(async () => {
        setIsDownloadingChangeLog(true);
        await downloadFileFromApi(async () => downloadChangeLog(activeReport?.id));
        setIsDownloadingChangeLog(false);
    }, [activeReport?.id]);

    const onDownloadExpenseSummaryClick = useCallback(async () => {
        setIsDownloadingExpenseSummary(true);
        await downloadFileFromApi(async () => downloadExpenseSummary(activeReport?.id));
        setIsDownloadingExpenseSummary(false);
    }, [activeReport?.id]);

    const decrementMonth = () => {
        const previousMonth = month === 1 ? MONTH_MAX : month - 1;
        const isPreviousMonthDecember = previousMonth === MONTH_MAX;
        const year = getYear(selectedYear);
        const previousYear = isPreviousMonthDecember ? Number(year) - 1 : Number(year);
        const newMonth = previousMonth;
        setCurrentMonth(newMonth);
        if (isPreviousMonthDecember) {
            setCurrentYear(previousYear.toString());
        }
    };
    const incrementMonth = () => {
        const isActiveMonthDecember = month === MONTH_MAX;
        const newMonth = isActiveMonthDecember ? 1 : month + 1;
        setCurrentMonth(newMonth);
        if (isActiveMonthDecember) {
            const year = getYear(selectedYear);
            setCurrentYear((Number(year) + 1).toString());
        }
    };
    const hasActiveReport = !!activeReport;
    const sharingPrefix = activeReport?.isShared ? 'un-' : '';
    const lockingPrefix = activeReport?.isLocked ? 'un' : '';
    const noteLabel = activeReport?.note ? 'Edit Note' : 'Add Note';
    const lockLabel = activeReport?.isLocked ? 'Unlock' : 'Lock';
    const canEditReportAndReportExists = canEditReport && hasActiveReport;
    const isViewChangesVisible =
        hasContents(activeReport?.changes) ||
        activeReport?.payrollReportUserSnapshots?.some((x) => hasValue(x.note));
    const showInvoiceSummary =
        canViewInvoiceSummary &&
        hasValue(activeReport?.individualBenefitsTotal) &&
        hasValue(activeReport?.teamBenefitsTotal);

    const { canApplyDeductions } = useHrsForPayrollReport(activeReport);
    const actions: IActionButtonItems = [
        {
            isVisible: canEditReportAndReportExists && !activeReport.isLocked,
            onClick: openRefreshReportModal,
            text: 'Refresh',
        },
        {
            dataCy: 'generate-payroll-report',
            isVisible: canEditReport,
            onClick: openGenerateReportModal,
            text: 'Generate',
        },
        {
            isVisible: isViewChangesVisible,
            onClick: openChangeLogModal,
            text: 'View Changes',
        },
        {
            isVisible: !!activeReport?.expenseSummary,
            onClick: openExpenseSummaryModal,
            text: 'View Monthly Expense Summary',
        },
        {
            isVisible: canEditReport && hasActiveReport,
            onClick: openTextAreaModal,
            text: noteLabel,
        },
        {
            isVisible: canApplyDeductions,
            onClick: openApplyDeductionsModal,
            text: 'Set Payroll Deductions',
        },
        {
            isVisible: canEditReportAndReportExists,
            onClick: openTogglePayrollReportLockedModal,
            text: lockLabel,
        },
        {
            isVisible: canEditReportAndReportExists && payrollReportSharingEnabled,
            onClick: openTogglePayrollReportSharedModal,
            text: `${upperFirst(sharingPrefix)}Share`,
        },
        {
            isLoading: isDownloadingPayrollReport,
            isVisible: canViewTeamSharedPayrollReports && hasActiveReport,
            onClick: onDownloadPayrollReportClick,
            text: 'Download Payroll Report',
        },
        {
            isLoading: isDownloadingChangeLog,
            isVisible: canViewTeamSharedPayrollReports && isViewChangesVisible,
            onClick: onDownloadChangeLogClick,
            text: 'Download Change Log',
        },
        {
            isLoading: isDownloadingExpenseSummary,
            isVisible: canViewTeamSharedPayrollReports && hasActiveReport,
            onClick: onDownloadExpenseSummaryClick,
            text: 'Download Monthly Expense Summary',
        },
        {
            dataCy: 'generate-1095c-part2-report',
            isVisible: canViewReport,
            onClick: openGenerate1095CPart2Modal,
            text: 'Generate ICHRA 1095-C Part II Report',
        },
        {
            dataCy: 'download-1095c-part3-report',
            isVisible: canDownload1095CPart3,
            onClick: openDownload1095CPart3Modal,
            text: 'Download ICHRA 1095-C Part III Report',
        },
        {
            isVisible: !activeReport?.isLocked && canDeleteReport && hasActiveReport,
            onClick: openDeleteReportModal,
            text: 'Delete',
        },
    ];

    const refreshReport = async () => {
        await dispatch(refreshPayrollReport(activeReport));
        closeRefreshReportModal();
    };
    const toggleReportLocked = async () => {
        await dispatch(togglePayrollReportLocked(activeReport?.id, lockingPrefix));
        closeTogglePayrollReportLockedModal();
    };
    const toggleReportShared = async () => {
        await dispatch(togglePayrollReportShared(activeReport?.id, sharingPrefix));
        closeTogglePayrollReportSharedModal();
    };
    const deleteReport = async () => {
        await dispatch(deletePayrollReport(activeReport));
        closeDeleteReportModal();
    };
    const saveNote = async (value: string | undefined) => {
        await dispatch(setPayrollReportNote(activeReport?.id, value as string));
        closeTextAreaModal();
    };

    const adpSharedText =
        adpIntegrationStatus === AdpIntegrationStatuses.Connected &&
        activeReport?.isLocked &&
        team?.setWageUpAndWithholdingOnPayrollReportShare
            ? "This will also set Wage+ amounts & Withholdings in the team's payroll system."
            : '';

    const toggleReportSharedText = (
        <div>
            <p>
                {activeReport?.isShared
                    ? 'Payroll Report will be un-shared with the team admins and payroll admins.'
                    : `Payroll Report will be shared with the team admins and payroll admins. An email including the link to this Payroll Report will be sent to the team admins and payroll admins. ${adpSharedText}`}
            </p>
            <p>{`Are you sure you want to ${sharingPrefix}share this Payroll Report?`}</p>
        </div>
    );
    return (
        <React.Fragment>
            {isTextAreaModalVisible && (
                <TextAreaModal
                    label="Payroll Report Note"
                    maxCharacters={NOTE_MAX_LENGTH}
                    onHide={closeTextAreaModal}
                    save={saveNote}
                    showActivity={isLoadingSetNote}
                    title={noteLabel}
                    value={activeReport?.note}
                />
            )}
            {isChangeLogModalVisible && (
                <PayrollReportChangeLogModal
                    close={closeChangeLogModal}
                    payrollReport={activeReport}
                />
            )}
            {isGenerateReportModalVisible && (
                <GeneratePayrollReportModal
                    close={closeModal}
                    initialMonth={month}
                    initialYear={selectedYear}
                />
            )}
            {isGenerate1095CPart2ModalVisible && (
                <Generate1095CPart2ReportModal
                    close={closeGenerate1095CPart2Modal}
                    initialYear={selectedYear}
                />
            )}
            {isDownload1095CPart3ModalVisible && (
                <Download1095CPart3ReportModal
                    close={closeDownload1095CPart3Modal}
                    initialYear={selectedYear}
                />
            )}
            {isRefreshReportModalVisible && (
                <ConfirmationModal
                    body={
                        <div>
                            <p>
                                {activeReport?.isLocked
                                    ? `This Payroll Report has been locked in for this month.
                                    Any changes should take effect in a future report.
                                    You should only refresh the data in this report if you are absolutely sure it's necessary.`
                                    : `Refreshing the Payroll Report will pull in the latest data from the Platform to fill in the report.
                                    Any adjustments entered will not be affected.`}
                            </p>
                            <p>Are you sure you want to refresh this Payroll Report?</p>
                        </div>
                    }
                    onNoClick={closeRefreshReportModal}
                    onYesClick={refreshReport}
                    showActivity={isLoading}
                    title="Refresh Payroll Report?"
                />
            )}
            {isTogglePayrollReportLockedModalVisible && (
                <ConfirmationModal
                    body={`Are you sure you want to ${lockingPrefix}lock this Payroll Report?`}
                    onNoClick={closeTogglePayrollReportLockedModal}
                    onYesClick={toggleReportLocked}
                    showActivity={isLoadingTogglePayrollReportLocked}
                    title={`${lockLabel} Payroll Report?`}
                />
            )}
            {isTogglePayrollReportSharedModalVisible && (
                <ConfirmationModal
                    body={toggleReportSharedText}
                    onNoClick={closeTogglePayrollReportSharedModal}
                    onYesClick={toggleReportShared}
                    showActivity={isLoadingTogglePayrollReportShared}
                    title={`${upperFirst(sharingPrefix)}Share Payroll Report?`}
                />
            )}
            {isDeleteReportModalVisible && (
                <ConfirmationModal
                    body={`Are you sure you want to delete the Payroll Report for ${
                        MONTHS[month - 1]?.name
                    } ${selectedYear}?`}
                    onNoClick={closeDeleteReportModal}
                    onYesClick={deleteReport}
                    showActivity={isLoading}
                    title="Delete Payroll Report"
                />
            )}
            {isApplyDeductionsModalVisible && (
                <ApplyDeductionsModal
                    close={closeApplyDeductionsModal}
                    payrollReportId={activeReport?.id}
                    {...activeReport}
                />
            )}
            {isExpenseSummaryModalVisible && (
                <ExpenseSummaryModal
                    close={closeExpenseSummaryModal}
                    payrollReport={activeReport}
                />
            )}
            <TableHeader
                dropdownWhenLengthGreaterThan={1}
                items={actions}
                OverrideLeftSide={
                    <Stack
                        alignItems="center"
                        direction={{ md: 'row', xs: 'column' }}
                        justifyContent="center"
                        spacing={2}
                    >
                        <PageHeader variant="h4">Payroll Reports</PageHeader>
                        <Select
                            items={years}
                            name="selectedYear"
                            onChange={onChange(setCurrentYear)}
                            optionText="name"
                            optionValue="name"
                            sx={{ maxWidth: 100, minWidth: 100 }}
                            value={selectedYear}
                        />
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            spacing={2}
                        >
                            <IconButton aria-label="back month" onClick={decrementMonth}>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <Select
                                items={MONTHS}
                                name="month"
                                onChange={onChange(setCurrentMonth)}
                                optionText="name"
                                optionValue="value"
                                sx={{ minWidth: 130 }}
                                value={month}
                            />
                            <IconButton aria-label="forward month" onClick={incrementMonth}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Stack>
                        {isViewChangesVisible ? (
                            <Button
                                onClick={openChangeLogModal}
                                startIcon={<CompareArrowsIcon color="warning" />}
                                variant="outlined"
                            >
                                View Changes
                            </Button>
                        ) : (
                            <Tooltip title="No changes compared to last month.">
                                <CompareArrowsIcon color="disabled" />
                            </Tooltip>
                        )}
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            spacing={2}
                        >
                            {activeReport?.isLocked && (
                                <Tooltip title="The payroll report for this month is locked. Changes are restricted.">
                                    <LockOutlinedIcon />
                                </Tooltip>
                            )}
                            {activeReport?.note && (
                                <Tooltip title={activeReport?.note}>
                                    {canEditReport ? (
                                        <IconButton color="secondary" onClick={openTextAreaModal}>
                                            <ChatOutlinedIcon />
                                        </IconButton>
                                    ) : (
                                        <ChatOutlinedIcon color="secondary" />
                                    )}
                                </Tooltip>
                            )}
                            {activeReport?.isShared && (canEditReport || isRhTeamMember) && (
                                <Tooltip title="The payroll report for this month has been shared with the Team Admin(s).">
                                    <GroupIcon color="secondary" />
                                </Tooltip>
                            )}
                            {showInvoiceSummary && (
                                <React.Fragment>
                                    <InvoiceSummary payrollReport={activeReport} />
                                    <DeductionDeadlineDay
                                        deadlineDay={team?.deductionDeadlineDay}
                                        finchPostTaxDeductionStatus={
                                            team?.finchPostTaxDeductionStatus
                                        }
                                        finchPreTaxDeductionStatus={
                                            team?.finchPreTaxDeductionStatus
                                        }
                                        hrisProvider={team?.hrsIntegrationProvider}
                                        payrollReport={activeReport}
                                    />
                                </React.Fragment>
                            )}
                        </Stack>
                    </Stack>
                }
                stackDirection={{ md: 'row', xs: 'column' }}
            />
        </React.Fragment>
    );
};

export default hot(module)(PayrollReportsPageHeader);
