import Grid from '@mui/material/Grid';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { GET_USER_PROFILE_ACTION, getUserProfile } from 'actions/user/getUserProfile';
import { IYourJobDto } from 'api/generated/models';
import { SingleDateMask } from 'components/DateTextField';
import Form from 'components/Form';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { isUndefined } from 'lodash';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatDateForDisplay } from 'utilities/format';
import { hasValue } from 'utilities/index';
import { object, string } from 'yup';
import { TeamStateIds, UserStatus } from 'api/generated/enums';
import useTeamProps from 'hooks/useTeamProps';

const TWO_HUNDRED_FIFTY_SIX = 256;

const schema = object({
    hireDate: string()
        .required()
        .isValidDate()
        .label('Hire Date'),
    jobTitle: string()
        .trim()
        .required()
        .max(TWO_HUNDRED_FIFTY_SIX)
        .label('Job Title'),
});

const chunkIsUndefined = (chunk: IYourJobDto) =>
    isUndefined(chunk?.jobTitle) && isUndefined(chunk?.hireDate);

const YourJobPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user, userId } = useUserProps();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_USER_PROFILE_ACTION, LIST_VISIBLE_FLOWS_ACTION),
    }));
    const { errors, validate } = useForm(schema);
    const { team } = useTeamProps();
    const [initialChunk, setInitialChunk] = useState<IYourJobDto>(currentTask.response);
    const [chunk, setChunk] = useState<IYourJobDto>(currentTask.response);

    const hasEmptyValues = (obj: IYourJobDto) => {
        if(obj){
            return  Object.values(obj).every(value => value === '');
        }
        return false;
       };
    const isRenewal =
        team?.teamStateId === TeamStateIds.Renewing &&
        [UserStatus.Renewing, UserStatus.Waived].contains(user?.status);

    const handleChunkChange = (field: string, value: string) => {
        let newChunk;
        switch (field) {
            case 'jobTitle':
                newChunk = { ...chunk, jobTitle: value };
                break;
            case 'hireDate':
                newChunk = { ...chunk, hireDate: value };
                break;
            default:
                return;
        }
        setChunk(newChunk);
    };

    const handleSubmit = async () => {
        const { data, isValid } = await validate({
            hireDate: chunk.hireDate,
            jobTitle: chunk.jobTitle,
        });

        if (isValid) {
            dispatch(completeTask(currentTask.globalId, data as IYourJobDto));
        }
    };

    useEffect(() => {
        if (!hasValue(user)) {
            dispatch(getUserProfile(userId, isCurrent));
        } else if (chunkIsUndefined(initialChunk) || (hasEmptyValues(initialChunk) && isRenewal)) {
            const newChunk = {
                hireDate: formatDateForDisplay(user?.hireDate) ?? '',
                jobTitle: user?.jobTitle ?? '',
            };
            setInitialChunk(newChunk as IYourJobDto);
            setChunk(newChunk as IYourJobDto);
        }
    }, [dispatch, initialChunk, isCurrent, isRenewal, user, userId]);

    return (
        <Grid container direction="column" gap={3} maxWidth={MAX_WIDTH}>
            <IchraFlowTitle title="Your Job" />
            <Skeleton
                isEnabled={isLoading}
                rowProps={ichraFlowSkeletonRowProps}
                structure={[
                    [
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 55,
                            sx: { mb: 1 },
                        },
                        {
                            columnProps: { flex: 1 },
                            count: 1,
                            height: 55,
                            sx: { mb: 1 },
                        },
                    ],
                ]}
            >
                <Form>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item lg={6} xs={12}>
                            <TextField
                                errors={errors?.jobTitle}
                                label="Job Title"
                                name="jobTitle"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.jobTitle ?? ''}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextField
                                errors={errors?.hireDate}
                                InputProps={{ inputComponent: SingleDateMask }}
                                label="Hire Date"
                                name="hireDate"
                                onChange={(e) => handleChunkChange(e.target.name, e.target.value)}
                                value={chunk?.hireDate ?? ''}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Skeleton>
            <Grid item>
                <IchraFlowButtons
                    currentTaskId={currentTask.globalId}
                    handleNext={handleSubmit}
                    isLoading={isLoading}
                    showPreviousButton
                />
            </Grid>
        </Grid>
    );
};

export default hot(module)(YourJobPage);
