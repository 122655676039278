import { COPY_HOUSEHOLD_INFO_ACTION, copyHouseholdInfo } from 'actions/user/copyHouseholdInfo';
import {
    ReimbursementReportFrequencies,
    ShoppingConfigurationIds,
    SurveyTypes,
    TeamStateIds,
} from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import {
    EditPayrollReports,
    EditSubmittedExpense,
    EditSurveyTypeToSend,
    SendSurveys,
    ViewPayrollReports,
    ViewSurveyTypeToSend,
} from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import ConfirmationModal from 'components/ConfirmationModal';
import { IValueType } from 'components/EditableAttribute';
import EditableDateAttribute from 'components/EditableDateAttribute';
import EditableNumberAttribute from 'components/EditableNumberAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import EditableTextAttribute from 'components/EditableTextAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import EditableYesNoSelectAttribute from 'components/EditableYesNoSelectAttribute';
import ProfileAttribute from 'components/ProfileAttribute';
import SingleButtonModal from 'components/SingleButtonModal';
import {
    ICHRA_SHOPPING_CONFIGURATION_ITEMS,
    WAGE_PLUS_SHOPPING_CONFIGURATION_ITEMS,
    shoppingConfigurationIdNames,
} from 'constants/shoppingConfigurations';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import ProfileSectionHeader from 'pages/teamProfile/ProfileSectionHeader';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isAdvisorSelector, isInRoleSelector, isRhSelector } from 'selectors/role';
import { enumToNameValueArray, flagsToValuesArray, hasValue, isTrue, nameof } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { DISPLAY_DATE_FORMAT } from 'utilities/moment';
import { getYears } from 'utilities/year';
import { array, number, string } from 'yup';

const VALID_FEIN_LENGTH = 9;
const validFeinValue = (value: string | undefined) => {
    const digitsOnly = value?.removeNonNumericCharacters();
    return !hasValue(value) || digitsOnly?.length === VALID_FEIN_LENGTH;
};
const EXPENSE_LOCK_DATE_OFFSET_MAXIMUM_VALUE = 30;
const EXPENSE_LOCK_DATE_OFFSET_MINIMUM_VALUE = -30;

const inputNumberProps = {
    min: 0,
    placeholder: 'Enter a number',
    type: 'number',
};

const surveyTypeFormatter = (x: string | undefined) => startCase(x);

const SURVEY_TYPE_OPTIONS = enumToNameValueArray(SurveyTypes, {
    formatName: surveyTypeFormatter,
    nameKey: 'text',
});

const getNthString = (d: IValueType) => {
    let suffix = 'th';
    const value = Number(d);
    if (isNaN(value) || !hasValue(d)) {
        return '';
    }
    // prettier-ignore
    if (value < 4 || value > 20) { // NOSONAR
        switch (
        value % 10 // NOSONAR
            ) {
            case 1:
                suffix = 'st';
                break;
            case 2: // NOSONAR
                suffix = 'nd';
                break;
            case 3: // NOSONAR
                suffix = 'rd';
                break;
        }
    }
    return `${value}${suffix}`;
};

const MAX_YEAR_MODIFIER = 1;
const YEAR_ITEMS = getYears(() => MAX_YEAR_MODIFIER).map((x) => ({ name: `${x}` }));
const MONTHLY_PAYROLL_AND_REIMBURSEMENT_TRIGGER_DAYS = Array.from({ length: 28 }, (_, i) => ({
    text: getNthString(i + 1),
    value: i + 1,
}));
const REIMBURSEMENT_REPORT_FREQUENCY_ITEMS = (enumToNameValueArray(ReimbursementReportFrequencies, {
    formatName: startCase,
    nameMap: {
        SemiMonthly: 'Semi-Monthly',
    },
}) as unknown) as { name: string; value: number }[];

export const wagePlusShoppingConfigurationValidationSchema = array(number()).test(
    'has-both-marketplace-and-off-exchange',
    'Cannot have both Marketplace and Off-Exchange selected',
    (value) =>
        !(
            value?.includes(ShoppingConfigurationIds.Marketplace) &&
            value?.includes(ShoppingConfigurationIds.OffExchange)
        )
);
const SecondSection = ({
    readonly,
    save,
}: {
    readonly: boolean;
    save: ISaveEditableTextField<ITeam>;
}) => {
    const dispatch = useThunkDispatch();
    const {
        accountId,
        allowRecurringExpensesByDefault: initialAllowRecurringExpensesByDefault,
        allowBsbByDefault: initialAllowBsbByDefault,
        team,
        teamStateId,
        activeDate: initialActiveDate,
        calendlyAdvisementLink: initialCalendlyAdvisementLink,
        enrollmentDeadline: initialEnrollmentDeadline,
        fein: initialFein,
        inactiveDate: initialInactiveDate,
        usePremiumTaxCreditsDefault: initialUsePremiumTaxCreditsDefault,
        memberAllowShoppingDefault: initialMemberAllowShoppingDefault,
        originalStartDate: initialOriginalStartDate,
        payrollReportSharingEnabled: initialPayrollReportSharingEnabled,
        useIchraLaunchFlow: initialUseIchraLaunchFlow,
        useReimbursementProgramManagement: initialUseReimbursementProgramManagement,
        surveyYear: initialSurveyYear,
    } = useTeamProps();
    const {
        canSendSurveys,
        canEditSubmittedExpenses,
        canEditSurveyTypeToSend,
        canEditPayrollReports,
        canViewPayrollReports,
        canViewSurveyTypeToSend,
        isAdvisorOrRhOrPartnerAdmin,
        isRh,
        isRhOrAdvisor,
        isRhOrPartnerAdmin,
        showActivity,
    } = useSelector((state: AppStore) => {
        const isRhOrPartnerAdminProp =
            isRhSelector(state) || isInRoleSelector(state, ROLE_IDS.PARTNER_ADMIN);
        return {
            canEditPayrollReports: hasSomePermissions(state, EditPayrollReports),
            canEditSubmittedExpenses: hasSomePermissions(state, EditSubmittedExpense),
            canEditSurveyTypeToSend: hasSomePermissions(state, EditSurveyTypeToSend),
            canSendSurveys: hasSomePermissions(state, SendSurveys),
            canViewPayrollReports: hasSomePermissions(state, ViewPayrollReports),
            canViewSurveyTypeToSend: hasSomePermissions(state, ViewSurveyTypeToSend),
            isAdvisorOrRhOrPartnerAdmin: isAdvisorSelector(state) || isRhOrPartnerAdminProp,
            isRh: isRhSelector(state),
            isRhOrAdvisor: isRhSelector(state) || isAdvisorSelector(state),
            isRhOrPartnerAdmin: isRhOrPartnerAdminProp,
            showActivity: hasApiActivity(state, COPY_HOUSEHOLD_INFO_ACTION),
        };
    });
    const [fein, setFein] = useState(initialFein ?? '');
    const [fullTimeEmployeeCount, setFullTimeEmployeeCount] = useState(
        get(team, 'fullTimeEmployeeCount', '0').toString()
    );
    const [partTimeEmployeeCount, setPartTimeEmployeeCount] = useState(
        get(team, 'partTimeEmployeeCount', '0').toString()
    );
    const [surveyYear, setSurveyYear] = useState(initialSurveyYear);
    const [includeFaithBasedQuestionInSurvey, setIncludeFaithBasedQuestionInSurvey] = useState(
        team?.includeFaithBasedQuestionInSurvey?.toString() ?? 'true'
    );
    const [includeQualitativeQuestionsInSurvey, setIncludeQualitativeQuestionsInSurvey] = useState(
        team?.includeQualitativeQuestionsInSurvey?.toString() ?? 'true'
    );
    const [activeDate, setActiveDate] = useState(formatDateForDisplay(initialActiveDate));
    const [inactiveDate, setInactiveDate] = useState(formatDateForDisplay(initialInactiveDate));
    const [originalStartDate, setOriginalStartDate] = useState(
        formatDateForDisplay(initialOriginalStartDate)
    );
    const [calendlyAdvisementLink, setCalendlyAdvisementLink] = useState(
        initialCalendlyAdvisementLink ?? ''
    );
    const [medicareCalendlyLink, setMedicareCalendlyLink] = useState(
        team?.medicareCalendlyLink ?? ''
    );
    const [vipCalendlyLink, setVipCalendlyLink] = useState(team?.vipCalendlyLink ?? '');
    const [sbeEnrollmentCalendlyLink, setSbeEnrollmentCalendlyLink] = useState(
        team?.sbeEnrollmentCalendlyLink ?? ''
    );
    const [isColonialContractSigned, setIsColonialContractSigned] = useState(
        team?.isColonialContractSigned?.toString() ?? ''
    );
    const [previousActiveYear, setPreviousActiveYear] = useState<number | undefined>(0);
    const [showYearWarningModal, setShowYearWarningModal] = useState(false);
    const [showChangeWarningModal, setShowChangeWarningModal] = useState(false);
    const [showCopyHouseholdDataModal, setShowCopyHouseholdDataModal] = useState(false);
    const [usePremiumTaxCreditsDefault, setUsePremiumTaxCreditsDefault] = useState(
        initialUsePremiumTaxCreditsDefault?.toString() ?? ''
    );
    const [memberAllowShoppingDefault, setMemberAllowShoppingDefault] = useState(
        initialMemberAllowShoppingDefault?.toString()
    );
    const [wagePlusShoppingConfiguration, setWagePlusShoppingConfiguration] = useState<
        ShoppingConfigurationIds[]
    >(
        flagsToValuesArray(
            team?.wagePlusShoppingConfiguration,
            shoppingConfigurationIdNames,
            'value'
        ).map((x) => x['value'] as ShoppingConfigurationIds)
    );
    const [ichraShoppingConfiguration, setIchraShoppingConfiguration] = useState<
        ShoppingConfigurationIds[]
    >(
        flagsToValuesArray(
            team?.ichraShoppingConfiguration,
            shoppingConfigurationIdNames,
            'value'
        ).map((x) => x['value'] as ShoppingConfigurationIds)
    );
    const [enrollmentDeadline, setEnrollmentDeadline] = useState(
        formatDateForDisplay(initialEnrollmentDeadline)
    );
    const [useIchraLaunchFlow, setUseIchraLaunchFlow] = useState(
        initialUseIchraLaunchFlow?.toString()
    );
    const [useReimbursementProgramManagement, setUseReimbursementProgramManagement] = useState(
        initialUseReimbursementProgramManagement?.toString()
    );
    const [reimbursementReportFrequencyId, setReimbursementReportFrequencyId] = useState(
        team?.reimbursementReportFrequencyId
    );
    const [expenseLockDateOffset, setExpenseLockDateOffset] = useState(team?.expenseLockDateOffset);
    const [payrollReportSharingEnabled, setPayrollReportSharingEnabled] = useState(
        initialPayrollReportSharingEnabled?.toString()
    );
    const [payrollReportLockDay, setPayrollReportLockDay] = useState(
        team?.payrollReportLockDay.toString()
    );
    const [payrollReportShareDay, setPayrollReportShareDay] = useState(
        team?.payrollReportShareDay?.toString()
    );
    const [allowRecurringExpensesByDefault, setAllowRecurringExpensesByDefault] = useState(
        initialAllowRecurringExpensesByDefault?.toString()
    );
    const [allowBsbByDefault, setAllowBsbByDefault] = useState(
        initialAllowBsbByDefault?.toString()
    );
    const [defaultSurveyTypeToSend, setDefaultSurveyTypeToSend] = useState(
        team?.defaultSurveyTypeToSend
    );
    const [isIchraExpress, setIsIchraExpress] = useState(
        team?.isIchraExpress?.toString() ?? 'false'
    );
    const [show1095CCoding, setshow1095CCoding] = useState(team?.show1095CCoding.toString());

    const isDevMode = process.env['DEV_MODE'] === 'true';

    const onChange = useCallback(
        (setValue) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            const v = value === 'undefined' ? null : value;
            setValue(v);
        },
        []
    );

    const teamStatus = useMemo(() => startCase(TeamStateIds[teamStateId]), [teamStateId]);
    const closeYearWarningModal = useCallback(() => setShowYearWarningModal(false), []);
    const closeChangeWarningModal = useCallback(() => {
        setShowChangeWarningModal(false);
        setActiveDate(formatDateForDisplay(initialActiveDate));
    }, [initialActiveDate]);
    const onYesChangeWarningModal = useCallback(async () => {
        setShowChangeWarningModal(false);
        await save('activeDate', activeDate);
    }, [activeDate, save]);
    const closeCopyHouseholdDataModal = useCallback(() => setShowCopyHouseholdDataModal(false), []);
    const onYesCopyHouseholdDataModal = useCallback(async () => {
        const sourceYear = previousActiveYear ?? new Date().getUTCFullYear();
        await dispatch(
            copyHouseholdInfo(team?.teamId, sourceYear, activeDate?.getYear(DISPLAY_DATE_FORMAT))
        );
        setShowCopyHouseholdDataModal(false);
    }, [dispatch, team, previousActiveYear, activeDate]);
    const handleActiveDateChange = useCallback(
        async (name, value) => {
            const newYear = value?.getYear(DISPLAY_DATE_FORMAT);
            const initialYear = initialActiveDate?.getYear();
            if (teamStateId !== TeamStateIds.Prospect) {
                if (!newYear || newYear !== initialYear) {
                    setActiveDate(formatDateForDisplay(initialActiveDate));
                    setShowYearWarningModal(true);
                    return;
                }

                setShowChangeWarningModal(true);
            } else {
                await save(name, value);

                if (
                    (!initialYear && newYear !== new Date().getUTCFullYear()) ||
                    (initialYear && newYear && newYear !== initialYear)
                ) {
                    setPreviousActiveYear(initialYear);
                    setShowCopyHouseholdDataModal(true);
                    return;
                }
            }
        },
        [initialActiveDate, save, teamStateId]
    );
    const saveShoppingConfiguration: ISaveEditableTextField<ITeam> = async (name, valueAsArray) => {
        const value = (valueAsArray as string[]).reduce(
            (previousValue, currentValue) => Number(currentValue) | previousValue,
            0
        );
        await save(name, value);
    };
    return (
        <React.Fragment>
            <ProfileSectionHeader headerText="Team Settings" />
            {showYearWarningModal && (
                <SingleButtonModal
                    body="Active Date year cannot be changed if a team has a proposal generated. However, month and day may be changed. If you need to change the year, please contact the engineering team"
                    buttonClickHandler={closeYearWarningModal}
                    buttonText="OK"
                    title="Warning"
                />
            )}
            {showChangeWarningModal && (
                <ConfirmationModal
                    body={`Setting the active date of ${activeDate} for this team will change the active date value for all members on this team. Are you sure you want to do that?`}
                    onNoClick={closeChangeWarningModal}
                    onYesClick={onYesChangeWarningModal}
                    title="Are You Sure?"
                />
            )}
            {showCopyHouseholdDataModal && (
                <ConfirmationModal
                    body="Do you want to copy over the household data collected for this team to the new Active Date Year? (This will not overwrite existing data for that year)"
                    onNoClick={closeCopyHouseholdDataModal}
                    onYesClick={onYesCopyHouseholdDataModal}
                    showActivity={showActivity}
                    title="Copy Household Data"
                />
            )}
            <ProfileAttribute label="Status">{teamStatus}</ProfileAttribute>
            {canSendSurveys && (
                <EditableSelectAttribute
                    formatter={(value) => value?.toString()}
                    infoTooltip={
                        'The year used in the Health Benefits Analysis surveys. If not set, the year defaults to ' +
                        'the year of the Team Active Date. If no Active Date is set then the year defaults to the current year.'
                    }
                    isOptional
                    items={YEAR_ITEMS}
                    label="Survey Year"
                    name="surveyYear"
                    onChange={onChange(setSurveyYear)}
                    optionText="name"
                    optionValue="name"
                    readonly={!isRhOrPartnerAdmin}
                    save={save}
                    validationSchema={string().label('Survey Year')}
                    value={surveyYear}
                />
            )}
            {canViewSurveyTypeToSend && (
                <EditableSelectAttribute
                    formatter={(x: IValueType) => surveyTypeFormatter(SurveyTypes[x as number])}
                    infoTooltip="This is what Survey Type to Send will be set to by default for new members added to to this team"
                    items={SURVEY_TYPE_OPTIONS}
                    label="Default Survey Type to Send"
                    name="defaultSurveyTypeToSend"
                    onChange={onChange(setDefaultSurveyTypeToSend)}
                    optionText="text"
                    optionValue="value"
                    readonly={!canEditSurveyTypeToSend}
                    save={save}
                    validationSchema={string()
                        .label('Survey Type to Send')
                        .required()}
                    value={defaultSurveyTypeToSend}
                />
            )}
            {isRhOrAdvisor && (
                <React.Fragment>
                    <EditableYesNoSelectAttribute
                        infoTooltip="This determines whether the question on interest in faith-based sharing options will be included in the HBA survey for members of this team."
                        label="Include Faith-Based Question in Survey"
                        name={nameof<ITeam>('includeFaithBasedQuestionInSurvey')}
                        onChange={onChange(setIncludeFaithBasedQuestionInSurvey)}
                        save={save}
                        validationSchema={string()
                            .required()
                            .label('Include Faith-Based Question in Survey')}
                        value={includeFaithBasedQuestionInSurvey}
                    />
                    <EditableYesNoSelectAttribute
                        infoTooltip="This determines whether the standard and custom qualitative questions will be included in the HBA survey for members of this team."
                        label="Include Qualitative Questions in Survey"
                        name={nameof<ITeam>('includeQualitativeQuestionsInSurvey')}
                        onChange={onChange(setIncludeQualitativeQuestionsInSurvey)}
                        save={save}
                        validationSchema={string()
                            .required()
                            .label('Include Qualitative Questions in Survey')}
                        value={includeQualitativeQuestionsInSurvey}
                    />
                </React.Fragment>
            )}
            <EditableDateAttribute
                data-cy="active-date-input"
                infoTooltip="The date on which benefits are expected to start for members of the team"
                isOptional
                label="Active Date"
                name="activeDate"
                onChange={onChange(setActiveDate)}
                readonly={!isRhOrPartnerAdmin}
                save={handleActiveDateChange}
                validationSchema={string()
                    .isValidDate(false)
                    .label('Active Date')}
                value={activeDate}
            />
            {isRhOrAdvisor && (
                <React.Fragment>
                    <EditableYesNoSelectAttribute
                        infoTooltip="This is what the Use Premium Tax Credits will be set to by default for new members added to this team"
                        label="Use Premium Tax Credits by Default"
                        name="usePremiumTaxCreditsDefault"
                        onChange={onChange(setUsePremiumTaxCreditsDefault)}
                        save={save}
                        value={usePremiumTaxCreditsDefault}
                    />
                    <EditableYesNoSelectAttribute
                        infoTooltip="This is what the member Allow Shopping field will be set to for new members added to this team"
                        label="Member Shopping Allowed by Default"
                        name="memberAllowShoppingDefault"
                        onChange={onChange(setMemberAllowShoppingDefault)}
                        save={save}
                        value={memberAllowShoppingDefault}
                    />
                    <EditableSelectAttribute
                        formatter={() =>
                            wagePlusShoppingConfiguration
                                .map(
                                    (x) =>
                                        shoppingConfigurationIdNames[
                                            Number(x) as ShoppingConfigurationIds
                                        ]
                                )
                                .joinSerialComma()
                        }
                        infoTooltip="Determines what plans/programs members will be able to see in the Shopping experience if they are in a Wage+ pathway"
                        items={WAGE_PLUS_SHOPPING_CONFIGURATION_ITEMS}
                        label="Wage+ Shopping Configuration"
                        name="wagePlusShoppingConfiguration"
                        onChange={onChange(setWagePlusShoppingConfiguration)}
                        optionText="text"
                        optionValue="value"
                        save={saveShoppingConfiguration}
                        SelectProps={{
                            multiple: true,
                            native: false,
                        }}
                        validationSchema={wagePlusShoppingConfigurationValidationSchema
                            .min(1)
                            .label('Wage+ Shopping Configuration')}
                        value={(wagePlusShoppingConfiguration as unknown) as string[]}
                    />
                    <EditableSelectAttribute
                        formatter={() =>
                            ichraShoppingConfiguration
                                .map(
                                    (x) =>
                                        shoppingConfigurationIdNames[
                                            Number(x) as ShoppingConfigurationIds
                                        ]
                                )
                                .joinSerialComma()
                        }
                        infoTooltip="Determines what plans/programs members will be able to see in the Shopping experience if they are in an ICHRA pathway"
                        items={ICHRA_SHOPPING_CONFIGURATION_ITEMS}
                        label="ICHRA Shopping Configuration"
                        name="ichraShoppingConfiguration"
                        onChange={onChange(setIchraShoppingConfiguration)}
                        optionText="text"
                        optionValue="value"
                        save={saveShoppingConfiguration}
                        SelectProps={{
                            multiple: true,
                            native: false,
                        }}
                        validationSchema={array()
                            .min(1)
                            .label('ICHRA Shopping Configuration')}
                        value={(ichraShoppingConfiguration as unknown) as string[]}
                    />
                </React.Fragment>
            )}
            <EditableDateAttribute
                infoTooltip="The deadline for members to make their benefits selections"
                isOptional
                label="Enrollment Deadline"
                name="enrollmentDeadline"
                onChange={onChange(setEnrollmentDeadline)}
                readonly={!isRhOrAdvisor}
                save={save}
                validationSchema={string()
                    .isValidDate(false)
                    .label('Enrollment Deadline')}
                value={enrollmentDeadline}
            />
            {isRh && (
                <React.Fragment>
                    <EditableYesNoSelectAttribute
                        data-cy="use-reimbursement-program-input"
                        infoTooltip="This will give team admins access to reimbursement program admin portal features, and allow members to submit and view their expenses in the platform."
                        label="Use Platform Reimbursement Program Management"
                        name="useReimbursementProgramManagement"
                        onChange={onChange(setUseReimbursementProgramManagement)}
                        optionText="text"
                        optionValue="value"
                        save={save}
                        validationSchema={string()
                            .required()
                            .label('Use Platform Reimbursement Program Management')}
                        value={useReimbursementProgramManagement}
                    />
                    {useReimbursementProgramManagement && (
                        <React.Fragment>
                            <EditableSelectAttribute
                                formatter={(value) =>
                                    REIMBURSEMENT_REPORT_FREQUENCY_ITEMS.find(
                                        (item) => item.value.toString() === value?.toString()
                                    )?.name
                                }
                                infoTooltip="This sets how often reimbursement reports will be processed for the team. If you change this value, it will not take affect until the next new report is created for an approved expense."
                                items={REIMBURSEMENT_REPORT_FREQUENCY_ITEMS}
                                label="Reimbursement Report Frequency"
                                name="reimbursementReportFrequencyId"
                                onChange={onChange(setReimbursementReportFrequencyId)}
                                optionText="name"
                                optionValue="value"
                                save={save}
                                validationSchema={string()
                                    .required()
                                    .label('Reimbursement Report Frequency')}
                                value={reimbursementReportFrequencyId}
                            />
                            <EditableNumberAttribute
                                allowNegative
                                infoTooltip="This sets is the number of days from the end of the report before the expenses on that report are locked and no longer editable."
                                label="Expense Lock Date Offset"
                                name="expenseLockDateOffset"
                                onChange={onChange(setExpenseLockDateOffset)}
                                readonly={readonly}
                                save={save}
                                validationSchema={number()
                                    .required()
                                    .max(EXPENSE_LOCK_DATE_OFFSET_MAXIMUM_VALUE)
                                    .min(EXPENSE_LOCK_DATE_OFFSET_MINIMUM_VALUE)
                                    .label('Expense Lock Date Offset')}
                                value={expenseLockDateOffset}
                            />
                            {canEditSubmittedExpenses && (
                                <EditableYesNoSelectAttribute
                                    infoTooltip="This is what the Allow Recurring Expenses field will be set to for new members added to this team."
                                    label="Allow Recurring Expenses by Default"
                                    name="allowRecurringExpensesByDefault"
                                    onChange={onChange(setAllowRecurringExpensesByDefault)}
                                    optionText="text"
                                    optionValue="value"
                                    save={save}
                                    validationSchema={string()
                                        .required()
                                        .label('Allow Recurring Expenses by Default')}
                                    value={allowRecurringExpensesByDefault}
                                />
                            )}
                            <EditableYesNoSelectAttribute
                                infoTooltip="This is what the Allow BSB field will be set to for new members added to this team."
                                label="Allow BSB by Default"
                                name="allowBsbByDefault"
                                onChange={onChange(setAllowBsbByDefault)}
                                save={save}
                                value={allowBsbByDefault}
                            />
                        </React.Fragment>
                    )}
                    <EditableYesNoSelectAttribute
                        label="Payroll Report Sharing Enabled"
                        name="payrollReportSharingEnabled"
                        onChange={onChange(setPayrollReportSharingEnabled)}
                        optionText="text"
                        optionValue="value"
                        save={save}
                        validationSchema={string()
                            .required()
                            .label('Payroll Report Sharing Enabled')}
                        value={payrollReportSharingEnabled}
                    />
                </React.Fragment>
            )}
            {canViewPayrollReports && (
                <React.Fragment>
                    {isTrue(payrollReportSharingEnabled) && (
                        <EditableSelectAttribute
                            formatter={getNthString}
                            infoTooltip="The day of the month each monthly payroll report will automatically be shared with team admin(s)."
                            isOptional
                            items={MONTHLY_PAYROLL_AND_REIMBURSEMENT_TRIGGER_DAYS}
                            label="Monthly Payroll Report Share Day"
                            name="payrollReportShareDay"
                            onChange={onChange(setPayrollReportShareDay)}
                            optionText="text"
                            optionValue="value"
                            readonly={!canEditPayrollReports}
                            save={save}
                            validationSchema={string().label('Monthly Payroll Report Share Day')}
                            value={payrollReportShareDay}
                        />
                    )}
                    <EditableSelectAttribute
                        formatter={getNthString}
                        infoTooltip="The day of the month each monthly payroll report will automatically lock."
                        items={MONTHLY_PAYROLL_AND_REIMBURSEMENT_TRIGGER_DAYS}
                        label="Monthly Payroll Report Lock Day"
                        name="payrollReportLockDay"
                        onChange={onChange(setPayrollReportLockDay)}
                        optionText="text"
                        optionValue="value"
                        readonly={!canEditPayrollReports}
                        save={save}
                        validationSchema={string()
                            .required()
                            .label('Monthly Payroll Report Lock Day')}
                        value={payrollReportLockDay}
                    />
                    <EditableYesNoSelectAttribute
                        infoTooltip="Turning this on will start calculating and showing recommended 1095-C codes in payroll reports for this team if they are an ALE."
                        label="Show 1095-C Coding Recommendations"
                        name="show1095CCoding"
                        onChange={onChange(setshow1095CCoding)}
                        optionText="text"
                        optionValue="value"
                        save={save}
                        validationSchema={string()
                            .required()
                            .label('Show 1095-C Coding Recommendations')}
                        value={show1095CCoding}
                    />
                </React.Fragment>
            )}
            {isAdvisorOrRhOrPartnerAdmin && (
                <React.Fragment>
                    <EditableDateAttribute
                        infoTooltip="The date on which benefits are expected to end for members of the team"
                        isOptional
                        label="Inactive Date"
                        name="inactiveDate"
                        onChange={onChange(setInactiveDate)}
                        readonly={!isRhOrPartnerAdmin}
                        save={save}
                        validationSchema={string()
                            .isValidDate(false)
                            .label('Inactive Date')}
                        value={inactiveDate}
                    />
                    <EditableDateAttribute
                        infoTooltip="The date on which benefits with Remodel Health first started for members of the team"
                        isOptional
                        label="Original Start Date"
                        name="originalStartDate"
                        onChange={onChange(setOriginalStartDate)}
                        readonly={!isRhOrPartnerAdmin}
                        save={save}
                        validationSchema={string()
                            .isValidDate(false)
                            .label('Original Start Date')}
                        value={originalStartDate}
                    />
                </React.Fragment>
            )}
            <ProfileAttribute label="ID">{accountId}</ProfileAttribute>
            <EditableNumberAttribute
                format="##-#######"
                infoTooltip="Federal Employer Identification Number"
                isNumericString={false}
                isOptional
                label="FEIN"
                name="fein"
                onChange={onChange(setFein)}
                placeholder="xx-xxxxxxx"
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .trim()
                    .test('validFein', 'FEIN must have 9 digits', validFeinValue)
                    .label('FEIN')}
                value={fein}
            />
            <EditableTextAttribute
                {...inputNumberProps}
                label="Full Time Employees"
                name="fullTimeEmployeeCount"
                onChange={onChange(setFullTimeEmployeeCount)}
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Full Time Employees')}
                value={fullTimeEmployeeCount}
            />
            <EditableTextAttribute
                {...inputNumberProps}
                label="Part Time Employees"
                name="partTimeEmployeeCount"
                onChange={onChange(setPartTimeEmployeeCount)}
                readonly={readonly}
                save={save}
                validationSchema={string()
                    .trim()
                    .required()
                    .label('Part Time Employees')}
                value={partTimeEmployeeCount}
            />
            {isAdvisorOrRhOrPartnerAdmin && (
                <React.Fragment>
                    <EditableTextAttribute
                        isOptional
                        label="Calendly Advisement Link"
                        name="calendlyAdvisementLink"
                        onChange={onChange(setCalendlyAdvisementLink)}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .url()
                            .label('Calendly Advisement Link')}
                        value={calendlyAdvisementLink}
                    />

                    <EditableTextAttribute
                        isOptional
                        label="Medicare Calendly Link"
                        name="medicareCalendlyLink"
                        onChange={onChange(setMedicareCalendlyLink)}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .url()
                            .label('Medicare Calendly Link')}
                        value={medicareCalendlyLink}
                    />

                    <EditableTextAttribute
                        isOptional
                        label="VIP Calendly Link"
                        name="vipCalendlyLink"
                        onChange={onChange(setVipCalendlyLink)}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .url()
                            .label('VIP Calendly Link')}
                        value={vipCalendlyLink}
                    />

                    <EditableTextAttribute
                        isOptional
                        label="SBE Enrollment Calendly Link"
                        name="sbeEnrollmentCalendlyLink"
                        onChange={onChange(setSbeEnrollmentCalendlyLink)}
                        save={save}
                        validationSchema={string()
                            .trim()
                            .url()
                            .label('SBE Enrollment Calendly Link')}
                        value={sbeEnrollmentCalendlyLink}
                    />
                </React.Fragment>
            )}
            {isRhOrPartnerAdmin && (
                <EditableYesNoSelectAttribute
                    isOptional
                    label="Colonial Ancillary Contract Signed"
                    name="isColonialContractSigned"
                    onChange={onChange(setIsColonialContractSigned)}
                    save={save}
                    value={isColonialContractSigned}
                />
            )}
            {isRh && (
                <EditableYesNoSelectAttribute
                    data-cy="use-ichra-launch-flow"
                    items={yesOrNo}
                    label="Use ICHRA Launch Flow"
                    name="useIchraLaunchFlow"
                    onChange={onChange(setUseIchraLaunchFlow)}
                    optionText="text"
                    optionValue="value"
                    save={save}
                    value={useIchraLaunchFlow}
                />
            )}
            {isRh && isDevMode && (
                <EditableYesNoSelectAttribute
                    label="Is ICHRA Express"
                    name="isIchraExpress"
                    onChange={onChange(setIsIchraExpress)}
                    optionText="text"
                    optionValue="value"
                    save={save}
                    value={isIchraExpress}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(SecondSection);
