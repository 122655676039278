import commonAction from 'actions/commonAction';
import HrsApi from 'api/generated/HrsApi';

export const SET_PAYROLL_DEDUCTIONS_ACTION = 'SET_PAYROLL_DEDUCTIONS';

export const setPayrollDeductions = (
    payrollReportId: string | undefined,
    effectiveDate?: string,
    overridePendingJob?: boolean | undefined
) =>
    commonAction(
        async () =>  new HrsApi().setPayrollDeductions(payrollReportId, effectiveDate, overridePendingJob),
        SET_PAYROLL_DEDUCTIONS_ACTION,
        {
            toastAcceptedMessage: result => result.data.message && result.data.message.trim() !== ''
                ? `Pending jobs will require ${result.data.daysToComplete} days to complete.`
                : 'There is already a pending deductions job for at least one user',
            toastErrorMessage: 'Failed to apply withholdings and Wage+ amounts',
            toastSuccessMessage: 'Successfully applied withholdings and Wage+ amounts',
        }
    );



