import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { clearAccountSnapshot } from 'actions/clear';
import {
    GET_ACCOUNT_SNAPSHOT_ACTION,
    getAccountSnapshot,
} from 'actions/payment/getAccountSnapshot';
import { setupInPayments } from 'actions/payment/setupInPayments';
import { PaymentsAccountStatusTypeIds } from 'api/generated/enums';
import PaymentsApi from 'api/generated/PaymentsApi';
import { UpdatePaymentsAccount } from 'api/generated/permissions';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import 'pages/payments/DebitCardInfo.css';
import PaymentsContentBlock from 'pages/payments/PaymentsContentBlock';
import PaymentsHeader from 'pages/payments/PaymentsHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';
import { isFinanceOrFullAdminSelector } from 'selectors/role';
import { formatCurrency, formatPhoneNumber } from 'utilities/format';
import { enumToString, hasValue } from 'utilities/index';

// https://www.marqeta.com/docs/developer-guides/using-marqeta-js
const marqetaConfig = (textColor: string) => ({
    component: {
        showPan: {
            cardCvv: {
                domId: 'mq-card-cvv',
                styles: {
                    span: {
                        color: textColor,
                        'font-family': 'Arial',
                    },
                },
            },
            cardExp: {
                domId: 'mq-card-exp',
                format: true,
                styles: {
                    span: {
                        color: textColor,
                        'font-family': 'Arial',
                    },
                },
            },
            cardPan: {
                domId: 'mq-card-pan',
                format: false,
                styles: {
                    span: {
                        color: textColor,
                        'font-family': 'Arial',
                    },
                },
            },
        },
    },
});

const PaymentsPage = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const { user } = useUserProps();
    const {
        isLoading,
        paymentsInfo: { accountSnapshotDto, canSetupInPayments },
        isFinanceOrAdmin,
        canUpdatePaymentsAccount,
    } = useSelector((state: AppStore) => ({
        canUpdatePaymentsAccount: hasSomePermissions(state, UpdatePaymentsAccount),
        isFinanceOrAdmin: isFinanceOrFullAdminSelector(state),
        isLoading: hasApiActivity(state, GET_ACCOUNT_SNAPSHOT_ACTION),
        paymentsInfo: state.paymentsInfo,
    }));
    const theme = useTheme();
    const [isCardInfoShown, setIsCardInfoShown] = useState(false);
    const onClick = async () => {
        setIsCardInfoShown(true);
        const response = await new PaymentsApi().getMarqetaToken(userId);
        const token = response.data;
        window.marqeta.bootstrap({
            ...marqetaConfig(theme.palette.text.primary),
            clientAccessToken: token,
        });
    };
    const isAccountMissing = !hasValue(accountSnapshotDto);
    const isUserBsbAllowed = user?.allowBsb === true;
    const canSetUpAccount =
        canSetupInPayments && isUserBsbAllowed && canUpdatePaymentsAccount && isFinanceOrAdmin;

    useEffect(() => {
        dispatch(getAccountSnapshot(userId));
        return () => {
            dispatch(clearAccountSnapshot());
        };
    }, [dispatch, userId]);

    const allowAccountRerun =
        accountSnapshotDto?.accountApplicationStatus === 'manual_review' ||
        accountSnapshotDto?.accountApplicationStatus === 'rejected';

    const onSetupInPaymentsClick = useCallback(async () => {
        await dispatch(setupInPayments(userId, allowAccountRerun));
    }, [dispatch, userId, allowAccountRerun]);

    const shouldHideButton =
        isCardInfoShown ||
        !hasValue(accountSnapshotDto?.debitLastFour) ||
        accountSnapshotDto?.debitCardStatus !== 'active';

    const cardHolderContent = [
        {
            id: 'name',
            label: 'Name on Account',
            value:
                `${accountSnapshotDto?.person?.firstName ?? ''} ${accountSnapshotDto?.person
                    ?.lastName ?? ''}`.trim() ?? 'Empty',
        },
        {
            id: 'email',
            label: 'Email Address',
            value: accountSnapshotDto?.person?.email ?? 'Empty',
        },
        {
            id: 'phone',
            label: 'Phone Number',
            value: formatPhoneNumber(accountSnapshotDto?.person?.phoneNumber) ?? 'Empty',
        },
        {
            id: 'address',
            label: 'Address',
            value: (
                <div style={{ lineHeight: '1.8' }}>
                    {accountSnapshotDto?.person?.address?.streetLine1 ?? ''}
                    <div>{accountSnapshotDto?.person?.address?.streetLine2 ?? ''}</div>
                    <div>
                        {accountSnapshotDto?.person?.address?.city ?? ''},{' '}
                        {accountSnapshotDto?.person?.address?.state ?? ''}{' '}
                        {accountSnapshotDto?.person?.address?.postalCode ?? ''}
                    </div>
                </div>
            ),
        },
    ];

    const cardContent = [
        {
            id: 'status',
            label: 'Account Application Status',
            value: accountSnapshotDto?.accountApplicationStatus ?? 'Empty',
        },
        {
            id: 'accountStatusTypeId',
            label: 'Account Status',
            value: hasValue(accountSnapshotDto?.accountStatusTypeId)
                ? enumToString(
                      PaymentsAccountStatusTypeIds,
                      accountSnapshotDto?.accountStatusTypeId as number
                  )?.toLowerCase()
                : 'No Account',
        },
        {
            id: 'debitCardStatus',
            label: 'Debit Card Status',
            value: accountSnapshotDto?.debitCardStatus ?? 'No Card Issued',
        },
        {
            id: 'cardNumber',
            label: 'Debit Card Number',
            value: (
                <div data-private id="mq-card-pan">
                    {!isCardInfoShown && `XXXX XXXX XXXX ${accountSnapshotDto?.debitLastFour}`}
                </div>
            ),
        },
        {
            id: 'exp',
            label: 'Exp',
            value: (
                <div data-private id="mq-card-exp">
                    {!isCardInfoShown && 'XX/XX'}
                </div>
            ),
        },
        {
            id: 'cvv',
            label: 'CVV',
            value: (
                <div data-private id="mq-card-cvv">
                    {!isCardInfoShown && 'XXX'}
                </div>
            ),
        },
        {
            id: 'accountNumber',
            label: 'Account Number',
            value: hasValue(accountSnapshotDto?.accountNumber)
                ? accountSnapshotDto?.accountNumber
                : 'No Account',
        },
        {
            id: 'routingNumber',
            label: 'Routing Number',
            value: accountSnapshotDto?.routingNumber ?? 'Empty',
        },
        {
            id: 'currentBalance',
            label: 'Current Balance',
            value: formatCurrency(accountSnapshotDto?.currentBalance, {
                emptyIfNaN: true,
                includeDollarSign: true,
                preserveDecimal: true,
            }),
        },
        {
            id: 'availableBalance',
            label: 'Available Balance',
            value: formatCurrency(accountSnapshotDto?.availableBalance, {
                emptyIfNaN: true,
                includeDollarSign: true,
                preserveDecimal: true,
            }),
        },
    ];

    let content = (
        <React.Fragment>
            {!user?.allowBsb && (
                <React.Fragment>
                    <p>
                        This member&apos;s Allow BSB property is false. Please check with the
                        Finance team before making any payments using the payment information.
                    </p>
                    <br />
                </React.Fragment>
            )}
            <Box display="flex" flexDirection={{ md: 'row', xs: 'column' }} gap={2}>
                <Box flex={1}>
                    <PaymentsContentBlock content={cardContent} title="Account Info" />
                </Box>
                <Box flex={1}>
                    <PaymentsContentBlock content={cardHolderContent} title="Person Info" />
                </Box>
            </Box>
        </React.Fragment>
    );

    if (isAccountMissing && canSetUpAccount) {
        content = <p>Use the action button to setup this member in Payments.</p>;
    } else if (isAccountMissing && !canSetupInPayments) {
        content = (
            <p>
                This member does not meet the criteria to be setup in Payments. In order to setup a
                member in Payments, the member must have a User Status of Active, Member Verified
                Info and SSN, Valid Home Address (PO Box addresses are not accepted), and an
                Enrolled or Effective Plan.
            </p>
        );
    } else if (isAccountMissing && !isUserBsbAllowed) {
        content = (
            <p>
                This member is set to not allow Payments account creation. In order to change this,
                go to their profile and update &quot;Allow BSB&quot; to true.
            </p>
        );
    } else if (isAccountMissing && !isFinanceOrAdmin) {
        content = <p>Please reach out to finance to setup this member in Payments.</p>;
    }

    let button = (
        <Button
            onClick={onClick}
            sx={{ visibility: shouldHideButton ? 'hidden' : 'visible' }}
            variant="outlined"
        >
            Show Card Numbers
        </Button>
    );

    if (canSetupInPayments && user?.allowBsb && canUpdatePaymentsAccount) {
        button = (
            <Button onClick={onSetupInPaymentsClick} variant="outlined">
                Setup In Payments
            </Button>
        );
    }

    if (allowAccountRerun && canUpdatePaymentsAccount && isFinanceOrAdmin) {
        button = (
            <Button onClick={onSetupInPaymentsClick} variant="outlined">
                Rerun Account Application
            </Button>
        );
    }

    return (
        <Paper>
            {isLoading ? (
                <React.Fragment>
                    <Skeleton />
                    <hr />
                    <Skeleton count={5} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <PaymentsHeader button={button} />
                    <hr />
                    {content}
                </React.Fragment>
            )}
        </Paper>
    );
};

export default hot(module)(PaymentsPage);

type Marqeta = {
    bootstrap: (config: unknown) => void;
};

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        marqeta: Marqeta;
    }
}
