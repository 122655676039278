import Chip from '@mui/material/Chip';
import { TeamStateIds } from 'api/generated/enums';
import useExternalTeamProps from 'components/authenticatedPageWrapper/useExternalTeamProps';
import useExternalUserProps from 'components/authenticatedPageWrapper/useExternalUserProps';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isRhSelector } from 'selectors/role';
import { formatDateForMonthYearDisplay } from 'utilities/format';
import { hasValue } from 'utilities/index';

const ActiveDateChip = () => {
    const { isRh } = useSelector((state: AppStore) => ({
        isRh: isRhSelector(state),
    }));
    const { user } = useExternalUserProps();
    const { teamStateId } = useExternalTeamProps();
    const color = hasValue(user?.activeDate) ? 'secondary' : 'error';
    const label = hasValue(user?.activeDate)
        ? formatDateForMonthYearDisplay(user?.activeDate)
        : 'No Active Date';
    const shouldShowActiveDate = isRh && hasValue(user) && teamStateId !== TeamStateIds.Prospect;

    return shouldShowActiveDate ? (
        <Tooltip title="Active date month/year">
            <Chip color={color} label={label} size="small" sx={{ ml: 1 }} variant="outlined" />
        </Tooltip>
    ) : (
        <React.Fragment />
    );
};

export default hot(module)(ActiveDateChip);
