import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { download1095CPart3Report } from 'actions/payroll/download1095CPart3Report';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';
import { downloadFileFromApi } from 'utilities/rhFile';
import { getYears } from 'utilities/year';

const years = getYears(() => 1).map((x) => ({ name: `${x}` }));

type IDownload1095CPart3ReportModal = {
    close: (year?: string, month?: number) => void;
    initialYear: string;
};

const Download1095CPart3ReportModal = ({ close, initialYear }: IDownload1095CPart3ReportModal) => {
    const { teamId } = useTeamProps();
    const [year, setYear] = useState(initialYear);
    const [isDownloadingReport, setIsDownloadingReport] = useState(false);
    const downloadReport = async () => {
        setIsDownloadingReport(true);
        await downloadFileFromApi(async () => download1095CPart3Report(teamId, +year));
        setIsDownloadingReport(false);
        close();
    };
    const cancel = () => close();
    useEffect(() => {
        setYear(initialYear);
    }, [initialYear]);
    return (
        <Dialog fullWidth maxWidth="xs" onClose={cancel} open>
            <DialogTitle>Download ICHRA 1095-C Part III Report</DialogTitle>
            <DialogContent dividers>
                <Typography className="mb-3">
                    Select the year you would like to download the report for.
                </Typography>
                <Stack gap={2} pt={1}>
                    <Select
                        data-cy="year"
                        items={years}
                        label="Year"
                        name="selectedYear"
                        onChange={onChange(setYear)}
                        optionText="name"
                        optionValue="name"
                        value={year}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} variant="text">
                    Close
                </Button>
                <LoadingButton
                    data-cy="generate-button"
                    loading={isDownloadingReport}
                    onClick={downloadReport}
                    variant="text"
                >
                    Download
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(Download1095CPart3ReportModal);
