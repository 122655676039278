import { FileResponse } from 'api/generated/models';
import { AxiosError, AxiosResponse } from 'axios';
import Toast from 'components/Toast';
import HTTP_STATUS from 'constants/responseStatuses';

export const downloadFileFromApi = async (
    downloadFileApi: () => Promise<AxiosResponse<FileResponse>>,
    toastErrorMessage?: string
) => {
    try {
        const {
            data: { data: blob, fileName },
        } = await downloadFileApi();
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName ?? 'file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === HTTP_STATUS.CLOUDFLARE_TIMEOUT) {
            Toast.error('This download is taking longer than the allotted download time. Please reach out to care@remodelhealth.com for assistance.');
        } else {
            Toast.error(toastErrorMessage ?? 'Download failed');
        }
    }
};
