import { GET_HOUSEHOLD_MEMBERS_ACTION } from 'actions/householdMember/getHouseholdMembers';
import { GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOffExchangeMarketplacePlanRatesForUser';
import { GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION } from 'actions/marketplacePlan/getOnExchangeMarketplacePlanRatesForUser';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { GET_PATHWAY_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayDataForUser';
import { ADD_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/addSelectedPlan';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { PathwayTypes, ShoppingConfigurationIds } from 'api/generated/enums';
import { IMarketplacePlanDto, IMediSharePlan } from 'api/generated/models';
import { orderBy, some } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { BenefitsMarket, QUERY_PARAM } from 'pages/shop/ShopFilters';
import { IShoppingMemberSelection } from 'pages/shop/shoppingMemberSelectionUtilities';
import { ISpousePlan } from 'pages/shop/SpousePlanCard';
import React, { createContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest, hasCompletedRequests } from 'selectors/activity';
import { hasFlag } from 'utilities/index';
import { isHealthcareSharingPathwayType, isIchraPathwayType } from 'utilities/pathways';

export const ON_AND_OFF_EXCHANGE = 5;
export type IPlanType = IMarketplacePlanDto | IMediSharePlan | ISpousePlan;

export type IShoppingContext = {
    benefitsMarkets?: BenefitsMarket[];
    excludedMembers?: string[];
    hasCompletedInitialLoading: boolean;
    hasFetchedPlans: boolean;
    isLoadingPlanData: boolean;
    isProvidersExpanded?: boolean;
    setBenefitsMarkets?: React.Dispatch<React.SetStateAction<BenefitsMarket[] | undefined>>;
    setExcludedMembers?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    setHasCompletedInitialLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    setHasFetchedPlans?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingPlanData?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsProvidersExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
    setShoppingComparePlans?: React.Dispatch<
        React.SetStateAction<IMarketplacePlanDto[] | undefined>
    >;
    setShoppingMemberSelections?: React.Dispatch<
        React.SetStateAction<IShoppingMemberSelection[] | undefined>
    >;
    shoppingComparePlans?: IMarketplacePlanDto[];
    shoppingMemberSelections?: IShoppingMemberSelection[];
};

export const ShoppingContext = createContext<IShoppingContext>({
    hasCompletedInitialLoading: false,
    hasFetchedPlans: false,
    isLoadingPlanData: false,
});

export const SORT = {
    ASCENDING: '0',
    DESCENDING: '1',
};

export const getDefaultBenefitsMarkets = (
    shoppingConfigurationId: ShoppingConfigurationIds | undefined,
    pathwayType: PathwayTypes | undefined
) => {
    const defaultMarkets: BenefitsMarket[] = [];
    if (
        hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.MediShare) &&
        isHealthcareSharingPathwayType(pathwayType)
    ) {
        defaultMarkets.push(BenefitsMarket.SharingPrograms);
    } else {
        if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.OffExchange)) {
            defaultMarkets.push(BenefitsMarket.OffExchange);
        }
        if (hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.Marketplace)) {
            defaultMarkets.push(BenefitsMarket.AcaMarketplace);
        }
    }

    return defaultMarkets;
};

export const ADVISEMENT_SCHEDULED_MESSAGE =
    'You have scheduled an advisement, check your calendly confirmation email for details.';

export const isBenefitsMarketQueryValid = (
    markets: BenefitsMarket[],
    shoppingConfigurationId: ShoppingConfigurationIds | undefined
) => {
    if (
        markets.includes(BenefitsMarket.SharingPrograms) &&
        !hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.MediShare)
    ) {
        return false;
    }
    if (
        markets.includes(BenefitsMarket.AcaMarketplace) &&
        !hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.Marketplace)
    ) {
        return false;
    }
    if (
        markets.includes(BenefitsMarket.OffExchange) &&
        !hasFlag(shoppingConfigurationId, ShoppingConfigurationIds.OffExchange)
    ) {
        return false;
    }
    return true;
};

const getSortOrder = (query?: URLSearchParams) =>
    query?.get(QUERY_PARAM.SORT) === SORT.DESCENDING ? 'desc' : 'asc';

export const shopPageSelector = (
    _match?: RouteComponentProps['match'],
    query?: URLSearchParams
) => (state: AppStore) => {
    const neededRequests = [GET_HOUSEHOLD_MEMBERS_ACTION, GET_PATHWAY_DATA_FOR_USER_ACTION];

    const props = {
        ...state.shoppingState,
        filteredPlans: [] as IPlanType[],
        hasCompletedInitialRequests: hasCompletedRequests(
            state,
            ...[GET_USER_PROFILE_ACTION, ...neededRequests]
        ),
        hasMultipleRecommendedPlans: (state.pathway?.pathwayRecommendedPlans?.length ?? 0) > 1,
        isSelectingPlan: hasApiActivity(state, ADD_SELECTED_PLAN_ACTION),
        isSomeMembersSelected: some(state.shoppingState.members),
        pathway: state.pathway,
        pathwayDataForUser: state.pathwayDataForUser,
        planDetailsActivity: hasApiActivity(
            state,
            GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
            GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION
        ),
    };

    const benefitsMarkets = query?.getAll(QUERY_PARAM.MARKET) as BenefitsMarket[];

    if (
        hasCompletedRequest(state, GET_MEDISHARE_PLANS_FOR_USER_ACTION) &&
        benefitsMarkets.includes(BenefitsMarket.SharingPrograms)
    ) {
        props.filteredPlans = state.mediSharePlans
            .filter((x) => !x.hasError)
            .map((x) => ({
                ...x,
                amount: x.totalAmount,
                isMediShare: true,
                issuer: { name: 'Medi-Share' },
                metalLevel: 'Other',
                type: 'PPO',
            }));
    } else if (
        (hasCompletedRequest(state, GET_ON_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION) &&
            benefitsMarkets.includes(BenefitsMarket.AcaMarketplace)) ||
        (hasCompletedRequest(state, GET_OFF_EXCHANGE_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION) &&
            benefitsMarkets.includes(BenefitsMarket.OffExchange))
    ) {
        const hasIchraPathway = isIchraPathwayType(props.pathwayDataForUser.pathwayType);
        let filteredPlans = state.marketplacePlans;
        if (!hasIchraPathway) {
            filteredPlans = filteredPlans.filter(
                (x) =>
                    !x.hasError &&
                    (benefitsMarkets.includes(BenefitsMarket.OffExchange)
                        ? isEmpty(x.exchange)
                        : !isEmpty(x.exchange))
            );
        }
        props.filteredPlans = filteredPlans.map((x) => ({
            ...x,
            amount: x.premiumWithCredits,
            isMarketplace: true,
        }));

        if (query?.has(QUERY_PARAM.CARRIER)) {
            const carrier = query.getAll(QUERY_PARAM.CARRIER);
            props.filteredPlans = props.filteredPlans.filter((plan) =>
                carrier.contains((plan as IMarketplacePlanDto).issuer?.name)
            );
        }

        if (query?.has(QUERY_PARAM.METAL_LEVEL)) {
            const metalLevel = query.getAll(QUERY_PARAM.METAL_LEVEL);
            props.filteredPlans = props.filteredPlans.filter((plan) =>
                metalLevel.contains((plan as IMarketplacePlanDto).metalLevel)
            );
        }

        if (query?.has(QUERY_PARAM.PLAN_TYPE)) {
            const planType = query.getAll(QUERY_PARAM.PLAN_TYPE);
            props.filteredPlans = props.filteredPlans.filter((plan) =>
                planType.contains((plan as IMarketplacePlanDto).planType)
            );
        }

        if (query?.has(QUERY_PARAM.PLAN_OPTIONS)) {
            const planOptions = query.getAll(QUERY_PARAM.PLAN_OPTIONS);
            props.filteredPlans = props.filteredPlans.filter(
                (plan) =>
                    planOptions.includes('HSA-Eligible') &&
                    (plan as IMarketplacePlanDto).isHsaEligible
            );
        }
    }

    const order = getSortOrder(query);
    props.filteredPlans = orderBy(props.filteredPlans, 'amount', order);

    return props;
};

export const getShowingMessage = (plans: IPlanType[], benefitsMarkets: BenefitsMarket[]) =>
    `Showing ${plans.length} ${
        benefitsMarkets?.includes(BenefitsMarket.SharingPrograms) ? 'programs' : 'plans'
    }`;
