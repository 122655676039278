import { getHouseholdMembers } from 'actions/householdMember/getHouseholdMembers';
import { getSelectedPlansForRenewal } from 'actions/selectedPlan/getSelectedPlansForRenewal';
import { EnrollmentStatuses, SurveyTypes, TaskItems, UserStatus } from 'api/generated/enums';
import { ITaskItem } from 'api/generated/models';
import { taskListTypes } from 'constants/tasks';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import MemberRenewalParentSpouseSubtaskList from 'pages/dashboard/taskLists/member/MemberRenewalParentSpouseSubtaskList';
import MemberRenewalStandardSubtaskList from 'pages/dashboard/taskLists/member/MemberRenewalStandardSubtaskList';
import AcceptServicesTask from 'pages/dashboard/taskLists/member/tasks/AcceptServicesTask';
import TaskListContainer from 'pages/dashboard/taskLists/TaskListContainer';
import { needSomeHouseholdSsnSelector } from 'pages/dashboard/taskListSelectors';
import React, { useCallback, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { greaterThan } from 'utilities';

type IMemberRenewalTaskList = {
    completedTasks: ITaskItem[];
    teamId: string;
};

const MemberRenewalTaskList = ({ completedTasks, teamId }: IMemberRenewalTaskList) => {
    const dispatch = useThunkDispatch();
    const {
        hasMemberVerifiedInfo,
        isInIchraPathway,
        memberVerifiedInfo,
        user,
        userId,
        year,
        yearlyUserInfo,
    } = useUserProps();
    const {
        hasEffectivePlans,
        useSpouseParentPlanShortCircuit,
        needSomeHouseholdSsn,
        useRestrategizedRenewalFlow,
    } = useSelector((state: AppStore) => ({
        hasEffectivePlans: state.selectedPlansForRenewal?.hasEffectivePlans,
        needSomeHouseholdSsn: needSomeHouseholdSsnSelector(state, memberVerifiedInfo),
        useRestrategizedRenewalFlow: state.pathwayDataForUser.useRestrategizedRenewalFlow,
        useSpouseParentPlanShortCircuit:
            state.selectedPlansForRenewal.allPlansParentOrSpouse &&
            state.ancillaryBenefits.length < 1,
    }));

    const isIchraSurvey = user?.surveyTypeToSend === SurveyTypes.ICHRA;
    const isUserWaived = user?.status === UserStatus.Waived;
    const renewalDecision = yearlyUserInfo?.renewalDecision;
    let hasOptedOut = false;

    const handleOptOutChange = (optingStatus: boolean) => {
        hasOptedOut = optingStatus;
    };

    const isTaskComplete = useCallback(
        (taskId) => completedTasks.some((x) => x.taskItemId === taskId),
        [completedTasks]
    );
    const isAcceptRHServicesComplete = useMemo(
        () => isTaskComplete(TaskItems.AcceptRHServices_Renewing),
        [isTaskComplete]
    );
    const isReviewMemberInfoComplete = useMemo(
        () => isTaskComplete(TaskItems.ReviewMemberInfo_Renewing),
        [isTaskComplete]
    );
    const isKeepOrChangeBenefitsComplete = useMemo(
        () => isTaskComplete(TaskItems.KeepOrChangeBenefits_Renewing),
        [isTaskComplete]
    );
    const isEnterSsnComplete = useMemo(
        () => isTaskComplete(TaskItems.EnterSocialSecurityNumbers_Renewing),
        [isTaskComplete]
    );
    const enableAcceptServices = useMemo(() => !isAcceptRHServicesComplete || isUserWaived, [
        isAcceptRHServicesComplete,
        isUserWaived,
    ]);
    const handleEnablingKeepOrChangeTask = useCallback(() => {
        if (useSpouseParentPlanShortCircuit) {
            return isAcceptRHServicesComplete;
        } else {
            return needSomeHouseholdSsn ? isEnterSsnComplete : isReviewMemberInfoComplete;
        }
    }, [
        isAcceptRHServicesComplete,
        isEnterSsnComplete,
        useSpouseParentPlanShortCircuit,
        isReviewMemberInfoComplete,
        needSomeHouseholdSsn,
    ]);
    const enableKeepOrChangeOrViewActive = useMemo(() => handleEnablingKeepOrChangeTask(), [
        handleEnablingKeepOrChangeTask,
    ]);
    let reviewInfoTitle =
        isInIchraPathway || isIchraSurvey
            ? 'Verify Your Information'
            : `Enter ${year} Income & Verify Your Information`;
    let reviewDescription =
        isInIchraPathway || isIchraSurvey
            ? `Review your household information for ${year}`
            : `Add your projected household income information for ${year} and review your information`;
    if (!hasMemberVerifiedInfo) {
        reviewInfoTitle = 'Complete Benefits Survey';
        reviewDescription = 'Please complete the benefits survey before moving forward';
    }
    const showKeepOrChange = useMemo(
        () => !isUserWaived && hasEffectivePlans && !useRestrategizedRenewalFlow,
        [hasEffectivePlans, isUserWaived, useRestrategizedRenewalFlow]
    );

    const commonProps = {
        completedTasks,
        enableAcceptServices,
        enableKeepOrChangeOrViewActive,
        isAcceptRHServicesComplete,
        isEnterSsnComplete,
        isKeepOrChangeBenefitsComplete,
        isReviewMemberInfoComplete,
        reviewDescription,
        reviewInfoTitle,
        showKeepOrChange,
        teamId,
    };

    useEffect(() => {
        if (userId) {
            dispatch(getHouseholdMembers(userId));
        }
    }, [dispatch, userId]);
    useEffect(() => {
        if (userId && greaterThan(year, 0) && !hasOptedOut) {
            dispatch(getSelectedPlansForRenewal(userId, year));
        }
    }, [dispatch, hasOptedOut, userId, year]);

    return (
        <React.Fragment>
            <TaskListContainer
                completeMessage="Great job! All open enrollment tasks have been completed."
                description=""
                isCompletedOverride={
                    yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.Waived &&
                    isAcceptRHServicesComplete
                }
                taskListType={taskListTypes.User}
                teamId={teamId}
                title="Open Enrollment Tasks"
            >
                <AcceptServicesTask
                    actionButtonText="Start"
                    complete={isAcceptRHServicesComplete}
                    description="View agreement and decide to continue with services"
                    enabled={enableAcceptServices}
                    hasOptedOut={handleOptOutChange}
                    key={TaskItems.AcceptRHServices_Renewing}
                    taskItemId={TaskItems.AcceptRHServices_Renewing}
                    title="Agree to Use Remodel Health"
                />
                {useSpouseParentPlanShortCircuit ? (
                    <MemberRenewalParentSpouseSubtaskList
                        {...commonProps}
                        renewalDecision={renewalDecision}
                    />
                ) : (
                    <MemberRenewalStandardSubtaskList {...commonProps} />
                )}
            </TaskListContainer>
        </React.Fragment>
    );
};

export default hot(module)(MemberRenewalTaskList);
