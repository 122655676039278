import Stack from '@mui/material/Stack';
import { clearTeamUserRoles } from 'actions/clear';
import { getLatestYearForRates } from 'actions/marketplacePlan/getLatestYearForRates';
import { APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT } from 'actions/pathwayBlueprint/applyCalculationForPathwayBlueprint';
import {
    CALCULATE_PATHWAY_BLUEPRINT_ACTION,
    calculatePathwayBlueprint,
} from 'actions/pathwayBlueprint/calculatePathwayBlueprint';
import { calculatePathwayUsers } from 'actions/pathwayBlueprint/calculatePathwayUsers';
import { clearPathwayBlueprint } from 'actions/pathwayBlueprint/clearPathwayBlueprint';
import {
    GET_PATHWAY_BLUEPRINT_ACTION,
    getPathwayBlueprint,
} from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { getTeamUserRoles } from 'actions/user/getTeamUserRoles';
import { getUserProfiles } from 'actions/user/getUserProfiles';
import { downloadPathwayBlueprint } from 'api/downloadPathwayBlueprint';
import { TeamStateIds } from 'api/generated/enums';
import { CalculatePathwayBlueprint, CreatePathway, EditPathway } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import { IActionButtonItems } from 'components/ActionButtons';
import DoubleBangIcon from 'components/DoubleBangIcon';
import IconTooltip from 'components/IconTooltip';
import PageSectionWrapper from 'components/PageSectionWrapper';
import Skeleton from 'components/Skeleton';
import TableHeader from 'components/TableHeader';
import Tooltip from 'components/Tooltip';
import { push } from 'connected-react-router';
import { AT_LEAST_ONE_ACTIVE_AND_PROJECTED_DIFFERENT_TEXT } from 'constants/contribution';
import { useGetPathwayBlueprintInterval } from 'hooks/useGetPathwayBlueprintInterval';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import IchraClasses from 'pages/pathwayBlueprint/ichraClasses/IchraClasses';
import NoPathwaysMessage from 'pages/pathwayBlueprint/NoPathwaysMessage';
import PathwayBlueprintModals from 'pages/pathwayBlueprint/PathwayBlueprintModals';
import PathwayBlueprintNameHeader from 'pages/pathwayBlueprint/PathwayBlueprintNameHeader';
import { setPathwayModalProps } from 'pages/pathwayBlueprint/pathwayModalActions';
import PathwayTables from 'pages/pathwayBlueprint/PathwayTables';
import UnknownPathwayTable from 'pages/pathwayBlueprint/UnknownPathwayTable';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    TEAMS_PATHWAY_BLUEPRINT_COST_COMPARISON_PATH,
    TEAMS_PATHWAY_BLUEPRINTS_PATH,
} from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity, hasApiActivityWithParams } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';
import { showActiveContributionByTeamState } from 'utilities/pathways';
import { downloadFileFromApi } from 'utilities/rhFile';

const getShowApplyContributions = (
    teamStateId: TeamStateIds,
    canRecalculatePathwayBlueprint: boolean,
    blueprintIsActive: boolean
) =>
    showActiveContributionByTeamState(teamStateId) &&
    canRecalculatePathwayBlueprint &&
    blueprintIsActive;

type IPathwayFilterContext = {
    filteredState?: string;
    includeInactive?: boolean;
    search?: string;
    setFilteredState?: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIncludeInactive?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setSearch?: React.Dispatch<React.SetStateAction<string | undefined>>;
};
type IIchraClassModalContext = {
    closeIchraClassModal?: () => void;
    ichraClassGlobalId?: string;
    openIchraClassModal?: () => void;
    setIchraClassGlobalId?: React.Dispatch<React.SetStateAction<string | undefined>>;
};
type IPathwayBlueprintContext = {
    selectedPathwayUserIds?: string[];
    setSelectedPathwayUserIds?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};

export const PathwayFilterContext = React.createContext<IPathwayFilterContext>({});
export const IchraClassModalContext = React.createContext<IIchraClassModalContext>({});
export const PathwayBlueprintContext = React.createContext<IPathwayBlueprintContext>({});

const PathwayBlueprintPage = () => {
    const dispatch = useThunkDispatch();
    const { pathwayBlueprintId } = useParams<{ pathwayBlueprintId?: string }>();
    const { teamId } = useTeamProps();
    const {
        canAddPathway,
        canEditPathway,
        canRecalculatePathwayBlueprint,
        isLoading,
        isLoadingCalculatePathwayBlueprint,
        latestYearForRates,
        pathwayBlueprint,
        userProfiles,
    } = useSelector((state: AppStore) => ({
        canAddPathway:
            hasSomePermissions(state, CreatePathway) && !state.pathwayBlueprint.isCalculating,
        canEditPathway: hasSomePermissions(state, EditPathway),
        canRecalculatePathwayBlueprint: hasSomePermissions(state, CalculatePathwayBlueprint),
        isLoading:
            hasApiActivity(state, GET_PATHWAY_BLUEPRINT_ACTION) ||
            hasApiActivityWithParams(state, APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT, {
                isBulk: 'true',
            }),
        isLoadingCalculatePathwayBlueprint: hasApiActivity(
            state,
            CALCULATE_PATHWAY_BLUEPRINT_ACTION
        ),
        latestYearForRates: state.marketplaceConfig.latestYearForRates,
        pathwayBlueprint: state.pathwayBlueprint,
        userProfiles: state.userProfiles,
    }));

    const { teamStateId } = useTeamProps();

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isUpdateLiveRates, setIsUpdateLiveRates] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [search, setSearch] = useState<string | undefined>('');
    const [filteredState, setFilteredState] = useState<string | undefined>('');
    const [includeInactive, setIncludeInactive] = useState<boolean | undefined>(false);
    const [statesAvailable, setStatesAvailable] = useState<{ name: string }[]>([]);
    const [ichraClassGlobalId, setIchraClassGlobalId] = useState<string | undefined>();
    const [selectedPathwayUserIds, setSelectedPathwayUserIds] = useState<string[] | undefined>([]);

    const {
        closeModal: closeEditModal,
        isVisible: isEditModalVisible,
        openModal: openEditModal,
    } = useModalState();
    const {
        closeModal: closeApplyContributionsModal,
        isVisible: isApplyContributionsModalVisible,
        openModal: openApplyContributionsModal,
    } = useModalState();
    const {
        closeModal: closeIchraClassModal,
        isVisible: isIchraClassModalVisible,
        openModal: openIchraClassModal,
    } = useModalState();

    useGetPathwayBlueprintInterval(pathwayBlueprintId, pathwayBlueprint?.isCalculating);

    const navigateToCostComparison = () =>
        dispatch(
            push(
                generatePath(TEAMS_PATHWAY_BLUEPRINT_COST_COMPARISON_PATH, {
                    pathwayBlueprintId,
                    teamId,
                })
            )
        );

    const recalculate = () => {
        dispatch(calculatePathwayBlueprint(pathwayBlueprintId));
    };

    const recalculateSelected = () => {
        dispatch(
            calculatePathwayUsers(selectedPathwayUserIds as string[], pathwayBlueprintId as string)
        );
    };

    const updateToLiveRates = useCallback(() => {
        setIsUpdateLiveRates(true);
        openEditModal();
    }, [openEditModal]);

    const showUseLiveRates =
        pathwayBlueprint.isProjection && pathwayBlueprint.year === latestYearForRates;

    const showApplyContributions = getShowApplyContributions(
        teamStateId,
        canRecalculatePathwayBlueprint,
        pathwayBlueprint.isActive
    );

    const onDownloadClick = useCallback(async () => {
        setIsDownloading(true);
        await downloadFileFromApi(async () => downloadPathwayBlueprint(pathwayBlueprintId));
        setIsDownloading(false);
    }, [pathwayBlueprintId]);

    const openPathwayModal = () =>
        dispatch(setPathwayModalProps({ isModalVisible: true, selectedItem: undefined }));
    const handleOpenIchraClassModal = () => {
        setIchraClassGlobalId(undefined);
        openIchraClassModal();
    };

    const items = [
        {
            isVisible: canEditPathway,
            onClick: openEditModal,
            text: 'Edit Blueprint',
        },
        {
            dataCy: 'add-pathway',
            isVisible: canAddPathway,
            onClick: openPathwayModal,
            text: 'Add Pathway',
        },
        {
            dataCy: 'add-ichra-class',
            isVisible: canAddPathway,
            onClick: handleOpenIchraClassModal,
            text: 'Add ICHRA Class',
        },
        {
            isLoading: isDownloading,
            isVisible: true,
            onClick: onDownloadClick,
            text: 'Download',
        },
        {
            isVisible: true,
            onClick: navigateToCostComparison,
            text: 'Compare to Old Plan',
        },
        {
            isLoading,
            isConfirm: true,
            isVisible: canRecalculatePathwayBlueprint,
            onClick: recalculate,
            text: `Recalculate${arrayHasValue(selectedPathwayUserIds) ? ' All' : ''}`,
        },
        {
            isLoading,
            isConfirm: true,
            isVisible: canRecalculatePathwayBlueprint && arrayHasValue(selectedPathwayUserIds),
            onClick: recalculateSelected,
            text: `Recalculate Selected (${selectedPathwayUserIds?.length})`,
        },
        {
            isLoading,
            isVisible: showApplyContributions,
            onClick: openApplyContributionsModal,
            text: 'Apply Contributions',
        },
        {
            dataCy: 'use-live-rates-pathway-blueprint',
            isVisible: showUseLiveRates,
            onClick: updateToLiveRates,
            text: 'Update to Live Rates',
        },
    ] as IActionButtonItems;
    const shouldShowSkeleton = (isLoading && isInitialLoad) || isLoadingCalculatePathwayBlueprint;

    const pathwayBlueprintContextValue = useMemo(
        () => ({ selectedPathwayUserIds, setSelectedPathwayUserIds }),
        [selectedPathwayUserIds]
    );

    const ichraClassContextValue = useMemo(
        () => ({
            closeIchraClassModal,
            ichraClassGlobalId,
            openIchraClassModal,
            setIchraClassGlobalId,
        }),
        [closeIchraClassModal, ichraClassGlobalId, openIchraClassModal]
    );

    const pathwayFilterValue = useMemo(
        () => ({
            filteredState,
            includeInactive,
            search,
            setFilteredState,
            setIncludeInactive,
            setSearch,
        }),
        [filteredState, includeInactive, search]
    );

    useEffect(() => {
        const formattedStatesToAdd: { name: string }[] = [];
        const allStates = userProfiles.map((userProfile) => userProfile.address?.state);
        const statesToAdd = allStates
            .filter((state, index, self) => hasValue(state) && self.indexOf(state) === index)
            .sort();
        statesToAdd.forEach((state) => formattedStatesToAdd.push({ name: state as string }));
        setStatesAvailable(formattedStatesToAdd);
    }, [userProfiles]);

    useEffect(() => {
        const getPathwayBlueprintData = async () => {
            await dispatch(getPathwayBlueprint(pathwayBlueprintId));
            setIsInitialLoad(false);
        };
        getPathwayBlueprintData();
    }, [dispatch, pathwayBlueprintId]);

    useEffect(() => {
        if (pathwayBlueprint.year) {
            dispatch(getUserProfiles(teamId, pathwayBlueprint.year, includeInactive));
            dispatch(setSelectedYear(pathwayBlueprint.year.toString()));
        }
    }, [dispatch, includeInactive, pathwayBlueprint.year, teamId]);

    useEffect(() => {
        dispatch(getTeamUserRoles(teamId, ROLE_IDS.TEAM_ADMIN));
        return () => {
            dispatch(clearTeamUserRoles());
        };
    }, [dispatch, teamId]);

    useEffect(() => {
        if (!latestYearForRates) {
            dispatch(getLatestYearForRates());
        }
    }, [dispatch, latestYearForRates]);

    useEffect(() => {
        if (
            hasValue(pathwayBlueprint.teamId) &&
            hasValue(teamId) &&
            pathwayBlueprint.teamId !== teamId
        ) {
            dispatch(clearPathwayBlueprint());
            dispatch(push(generatePath(TEAMS_PATHWAY_BLUEPRINTS_PATH, { teamId })));
        }
    }, [dispatch, pathwayBlueprint.teamId, teamId]);

    return (
        <PathwayBlueprintContext.Provider value={pathwayBlueprintContextValue}>
            <IchraClassModalContext.Provider value={ichraClassContextValue}>
                <PathwayBlueprintModals
                    closeApplyContributionsModal={closeApplyContributionsModal}
                    closeEditModal={closeEditModal}
                    isApplyContributionsModalVisible={isApplyContributionsModalVisible}
                    isEditModalVisible={isEditModalVisible}
                    isIchraClassModalVisible={isIchraClassModalVisible}
                    isUpdateLiveRates={isUpdateLiveRates}
                    setIsUpdateLiveRates={setIsUpdateLiveRates}
                />
                <PathwayFilterContext.Provider value={pathwayFilterValue}>
                    <Skeleton
                        count={1}
                        height="100px"
                        isEnabled={shouldShowSkeleton}
                        sx={{ mb: 2 }}
                    >
                        <PageSectionWrapper>
                            <TableHeader
                                disableDropdown={pathwayBlueprint?.isCalculating}
                                dropdownWhenLengthGreaterThan={0}
                                items={items}
                            >
                                <PathwayBlueprintNameHeader
                                    isLoading={shouldShowSkeleton}
                                    statesAvailable={statesAvailable}
                                >
                                    {pathwayBlueprint?.isActive && (
                                        <IconTooltip
                                            icon="checkmark"
                                            title="This is the active blueprint that is used for recalculating Wage+ values for each member"
                                            variant="success"
                                        />
                                    )}
                                    {pathwayBlueprint?.hasMismatchedContributionsUsers &&
                                        canEditPathway && (
                                            <Tooltip
                                                title={
                                                    AT_LEAST_ONE_ACTIVE_AND_PROJECTED_DIFFERENT_TEXT
                                                }
                                            >
                                                <DoubleBangIcon className="ml-1" />
                                            </Tooltip>
                                        )}
                                </PathwayBlueprintNameHeader>
                            </TableHeader>
                        </PageSectionWrapper>
                    </Skeleton>
                    <Skeleton
                        count={4}
                        height="400px"
                        isEnabled={shouldShowSkeleton}
                        sx={{ mb: 2 }}
                    >
                        <Stack gap={4}>
                            {!pathwayBlueprint?.pathways?.length && (
                                <NoPathwaysMessage onClick={openPathwayModal} />
                            )}
                            <UnknownPathwayTable />
                            <IchraClasses />
                            <PathwayTables />
                        </Stack>
                    </Skeleton>
                </PathwayFilterContext.Provider>
            </IchraClassModalContext.Provider>
        </PathwayBlueprintContext.Provider>
    );
};

export default hot(module)(PathwayBlueprintPage);
