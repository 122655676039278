import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const CALCULATE_PATHWAY_USERS_ACTION = 'CALCULATE_PATHWAY_USERS';

export const calculatePathwayUsers = (pathwayUserIds: string[], blueprintId: string) =>
    commonAction(
        async () => new PathwayBlueprintApi().calculatePathwayUsers(pathwayUserIds, blueprintId),
        CALCULATE_PATHWAY_USERS_ACTION,
        {
            additionalDispatchData: {
                params: { pathwayUserIds },
            },
            toastErrorMessage: 'Failed to recalculate users',
            toastSuccessMessage: 'Started recalculation of selected members',
        }
    );
