import { PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import CurrencyTextField from 'components/CurrencyTextField';
import { INumberTextFieldProps } from 'components/NumberTextField';
import AncillaryPlanInputs from 'components/planModal/AncillaryPlanInputs';
import CommonPlanInputs from 'components/planModal/CommonPlanInputs';
import MarketplacePlanInputs from 'components/planModal/MarketplacePlanInputs';
import MediSharePlanInputs from 'components/planModal/MediSharePlanInputs';
import { updatePlanInputs } from 'components/planModal/planModalActions';
import { IPlanInputs } from 'components/planModal/planModalState';
import TextField, { ITextFieldProps } from 'components/TextField';
import { CMS_OR_IDEON_PLAN_TYPES } from 'constants/selectedPlans';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const getErrors = (planInputs: IPlanInputs, name: string): string[] | undefined =>
    Object.prototype.hasOwnProperty.call(planInputs.errors ?? {}, name)
        ? planInputs.errors?.[name as keyof typeof planInputs.errors]
        : undefined;

export type IGetCommonPropsPlanInputs = (
    label: string,
    name: keyof IPlanInputs,
    isOptional?: boolean
) => ITextFieldProps;

type IPlanInputsProps = {
    planToEdit: ISelectedPlan | undefined;
};

const PlanInputs = ({ planToEdit }: IPlanInputsProps) => {
    const isEdit = !!planToEdit?.selectedPlanId;
    const dispatch = useThunkDispatch();
    const { marketplacePlans, planInputs } = useSelector((state: AppStore) => ({
        marketplacePlans: state.marketplacePlans,
        planInputs: state.planModalState.planInputs,
    }));
    const setInput = useCallback(
        ({ target: { name, value } }) => {
            if (planInputs.planType === PlanTypeIds.Ancillary && name === 'issuerName') {
                planInputs.healthCareTypeId = undefined;
            }
            if (
                CMS_OR_IDEON_PLAN_TYPES.includes(planInputs.planType) &&
                name === 'selectedMarketplacePlan'
            ) {
                planInputs.isHsaEligible = marketplacePlans
                    .find((x) => x.id === value)
                    ?.isHsaEligible?.toString();
            }
            dispatch(updatePlanInputs({ ...planInputs, [name]: value }));
        },
        [dispatch, marketplacePlans, planInputs]
    );
    const getCommonProps: IGetCommonPropsPlanInputs = useCallback(
        (label, name, isOptional = false): ITextFieldProps => ({
            isOptional,
            label,
            name,
            errors: getErrors(planInputs, name),
            onChange: setInput,
            placeholder: `Enter a ${label}`,
            value: planInputs[name],
        }),
        [planInputs, setInput]
    );
    const isCustomStateBasedExchangeOrCustomOffExchange = [
        PlanTypeIds.CustomStateBasedExchange,
        PlanTypeIds.CustomOffExchange,
    ].includes(planInputs.planType);
    if (CMS_OR_IDEON_PLAN_TYPES.includes(planInputs.planType)) {
        return <MarketplacePlanInputs getCommonProps={getCommonProps} planToEdit={planToEdit} />;
    } else if (planInputs.planType === PlanTypeIds.MediShare) {
        return <MediSharePlanInputs getCommonProps={getCommonProps} isEdit={isEdit} />;
    } else if (planInputs.planType === PlanTypeIds.VeteransAffairs) {
        return (
            <React.Fragment>
                <TextField {...getCommonProps('Plan Name', 'planName')} />
                <CommonPlanInputs
                    isStartDateRequired={[
                        PlanStateIds.Enrolled,
                        PlanStateIds.Effective,
                        PlanStateIds.Terminated,
                    ].contains(planInputs.planStateId)}
                />
            </React.Fragment>
        );
    } else if (planInputs.planType === PlanTypeIds.Ancillary) {
        return <AncillaryPlanInputs getCommonProps={getCommonProps} isEdit={isEdit} />;
    } else if (
        [
            PlanTypeIds.CustomOffExchange,
            PlanTypeIds.CustomMajorMedical,
            PlanTypeIds.CustomStateBasedExchange,
            PlanTypeIds.TermMedical,
            PlanTypeIds.CustomAncillary,
        ].includes(planInputs.planType)
    ) {
        return (
            <React.Fragment>
                <TextField
                    {...getCommonProps('Carrier Name', 'issuerName')}
                    autoFocus
                    disabled={isEdit}
                />
                <TextField {...getCommonProps('Plan Name', 'planName')} disabled={isEdit} />
                <CommonPlanInputs
                    isEndDateRequired={isCustomStateBasedExchangeOrCustomOffExchange}
                    isStartDateRequired={isCustomStateBasedExchangeOrCustomOffExchange}
                />
            </React.Fragment>
        );
    } else if (
        [PlanTypeIds.SpouseEmployer, PlanTypeIds.ParentEmployer].includes(planInputs.planType)
    ) {
        return (
            <React.Fragment>
                <CurrencyTextField
                    {...(getCommonProps('Deductible', 'deductible', true) as INumberTextFieldProps)}
                />
                <CommonPlanInputs isEndDateRequired isStartDateRequired />
            </React.Fragment>
        );
    } else if (
        [PlanTypeIds.CHIP, PlanTypeIds.Medicaid, PlanTypeIds.Medicare].includes(planInputs.planType)
    ) {
        return (
            <CommonPlanInputs
                isStartDateRequired={
                    planInputs.planType === PlanTypeIds.Medicare &&
                    planInputs.planStateId !== PlanStateIds.Selected
                }
            />
        );
    } else {
        return <React.Fragment />;
    }
};

export default hot(module)(PlanInputs);
