import Stack from '@mui/material/Stack';
import { BULK_SET_ALLOW_BSB_ACTION, bulkSetAllowBsb } from 'actions/team/bulkSetAllowBsb';
import ConfirmationModal from 'components/ConfirmationModal';
import Select from 'components/Select';
import Typography from 'components/Typography';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const SetAllowBsbForTeamModal = ({ close }: { close: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(state, BULK_SET_ALLOW_BSB_ACTION),
    }));
    const [allowBsb, setAllowBsb] = useState(true);
    const onYesClickSetAllowBsb = useCallback(async () => {
        await dispatch(bulkSetAllowBsb(teamId, allowBsb));
        close();
    }, [allowBsb, close, dispatch, teamId]);

    const body = useMemo(
        () => (
            <Stack gap={2}>
                <Typography mb={3} mt={1} mx={1} variant="body2">
                    Please select the value you would like to set <strong>Allow BSB</strong> to for
                    all members.
                </Typography>
                <Select
                    items={yesOrNo}
                    label="Allow BSB?"
                    onChange={onChange(setAllowBsb)}
                    optionText="text"
                    optionValue="value"
                    value={allowBsb.toString()}
                />
            </Stack>
        ),
        [allowBsb]
    );

    return (
        <ConfirmationModal
            body={body}
            noButtonText="Cancel"
            onNoClick={close}
            onYesClick={onYesClickSetAllowBsb}
            showActivity={showActivity}
            title="Set Allow BSB For All Members on Team"
            yesButtonText="Confirm"
        />
    );
};

export default hot(module)(SetAllowBsbForTeamModal);
