import { GroupLevels } from 'api/generated/enums';
import { IBaseUser, IUser } from 'api/generated/models';
import GENDERS from 'constants/genders';
import moment, { Moment, MomentInput } from 'moment';
import { hasValue, roundTwoDecimals, toBoolean } from 'utilities';
import { API_DATE_FORMAT, DISPLAY_DATE_FORMAT } from 'utilities/moment';

const CURRENCY_DECIMAL_INDEX = -3;
/**
 * Formats a number or string into a currency string
 * @param value - a value that can be parsed into a float
 * @param options - optional value with `emptyIfNaN` (default: `false`), `includeDollarSign` (default: `true`), and `preserveDecimal` (default: `false`)
 * @returns a string in the form '${dollars}.{cents}'. Returns empty string when `emptyIfNaN` is true and `N/A` otherwise if value cannot be parsed
 * @example formatCurrency('123', {preserveDecimal: true}) // output: $123.00
 */
export const formatCurrency = (
    value: number | string | undefined,
    options?: { emptyIfNaN?: boolean; includeDollarSign?: boolean; preserveDecimal?: boolean }
) => {
    const { emptyIfNaN, includeDollarSign, preserveDecimal } = {
        ...{ emptyIfNaN: false, includeDollarSign: true, preserveDecimal: false },
        ...options,
    };
    const definedValue = value === undefined ? NaN : value;
    const isValueNaN =
        typeof definedValue === 'number' ? isNaN(definedValue) : isNaN(parseFloat(definedValue));
    if (isValueNaN) {
        if (emptyIfNaN) {
            return '';
        }
        return 'N/A';
    }
    const roundedValue = preserveDecimal
        ? roundTwoDecimals(Number(definedValue))
        : Math.round(Number(definedValue));
    return roundedValue
        .toLocaleString('en-US', { currency: 'USD', style: 'currency' })
        .slice(includeDollarSign ? 0 : 1)
        .slice(0, preserveDecimal ? undefined : CURRENCY_DECIMAL_INDEX);
};
/**
 * Format a numeric value into a currency string, including 2 decimal places. Decimals are excluded if they are '.00'
 * @param value - a value that can be parsed into a float
 * @returns a string in the form '${dollars}.{cents}'. Returns empty string if value cannot be parsed
 * @example formatCurrency('123', {preserveDecimal: true}) // output: $123
 * @example formatCurrency('123.145', {preserveDecimal: true}) // output: $123.15
 */
export const formatCurrencyOptionalDecimal = (value: unknown) => {
    if ((typeof value === 'string' && isNaN(parseFloat(value))) || !hasValue(value)) {
        return '';
    }
    const roundedValue = roundTwoDecimals(Number(value));
    return roundedValue
        .toLocaleString('en-US', { currency: 'USD', style: 'currency' })
        .replace(/\.00$/, '');
};

export const formatGender = (gender: GENDERS) => (gender === GENDERS.MALE ? 'Male' : 'Female');

export const formatYesOrNo = (answer: unknown) => {
    if (!hasValue(answer)) {
        return '';
    }
    return toBoolean(answer) ? 'Yes' : 'No';
};

export const formatCoverageLevel = (
    groupLevels: {
        abbrev: string | undefined;
        fulltext: string | undefined;
        groupLevelId: GroupLevels;
    }[]
) => (value: string) => groupLevels.find((g) => g.groupLevelId.toString() === value)?.fulltext;

export const formatPhoneNumber = (phone: unknown) => {
    if (typeof phone === 'string') {
        const cleaned = ('' + phone).removeNonNumericCharacters();
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`; // NOSONAR
        }
    }
    return undefined;
};

export const formatSocialSecurityNumber = (socialSecurityNumber: string) => {
    const cleaned = (socialSecurityNumber ?? '').removeNonNumericCharacters();
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`; // NOSONAR
    }
    return null;
};

const apiDateRegex = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}/;
export const formatDateForDisplay = (date: string | undefined) => {
    if (date !== undefined && apiDateRegex.test(date)) {
        return moment(date, API_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    } else {
        return date;
    }
};

export const formatDateForMonthYearDisplay = (date: string | undefined) => {
    if (date !== undefined && apiDateRegex.test(date)) {
        return moment(date, API_DATE_FORMAT).format('MM/YYYY');
    } else {
        return date;
    }
};

const displayDateRegex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/;
export const formatDateForApi = (date: string | undefined) => {
    if (date !== undefined && displayDateRegex.test(date)) {
        return moment(date, DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT);
    } else {
        return date;
    }
};

export const formatTimeForDropdownValue = (date: MomentInput) => moment(date).format('HH:mm:ss');

export const formatTimeForDisplay = (date: MomentInput) => moment(date).format('LT');

export const formatDateAtTime = (date: MomentInput, defaultValue = '') => {
    if (!hasValue(date)) {
        return defaultValue;
    }
    return moment
        .utc(date)
        .local()
        .format('L [at] LT');
};

export const formatUserOnDateAtTime = (
    user: IBaseUser | IUser | undefined,
    date: Moment | string | undefined
) => `${user?.displayName} on ${formatDateAtTime(date)}`;
