import commonAction from 'actions/commonAction';
import TeamsApi from 'api/generated/TeamsApi';

export const BULK_SET_ALLOW_BSB_ACTION = 'BULK_SET_ALLOW_BSB';

export const bulkSetAllowBsb = (teamId: string, allowBsb: boolean) =>
    commonAction(
        async () => new TeamsApi().bulkSetAllowBsb(teamId, allowBsb),
        BULK_SET_ALLOW_BSB_ACTION,
        {
            toastErrorMessage: 'Unable to update Allow BSB value for all team members',
            toastSuccessMessage: 'Successfully updated Allow BSB value for all team members',
        }
    );
