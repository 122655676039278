import { Stack } from '@mui/material';
import ExternalLink from 'components/ExternalLink';
import React from 'react';
import { hot } from 'react-hot-loader';

const currentYear = new Date().getFullYear();

const Footer = () => {
    const version = process.env['VERSION'];
    const footerContent = (
        <React.Fragment>
            <span>
                {currentYear} © Remodel Health
                <br />v{version}
            </span>
            <span></span>
            <ExternalLink href="https://vault.pactsafe.io/s/7d06b652-ad04-44fe-8dfe-e393ad02d1b8/legal.html?g=3784#privacy-policy">
                Privacy Policy
            </ExternalLink>
        </React.Fragment>
    );

    return (
        <Stack color="white" direction="column-reverse" padding={2} textAlign="left">
            {footerContent}
        </Stack>
    );
};
export default hot(module)(Footer);
