import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import Toast from 'components/Toast';
import HTTP_STATUS from 'constants/responseStatuses';
import { ToastContent } from 'react-toastify';
import { AppStore } from 'reducers/appReducer';
import { Action, AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export type IAction<T> = { data: T; type: string };
export type AppStoreThunkDispatch<E = unknown, A extends Action = AnyAction> = ThunkDispatch<
    AppStore,
    E,
    A
>;
export type AxiosResponseWithError<T> = AxiosResponse<T> & { error: AxiosError<T> };
type ToastSuccessMessage<T> = ToastContent | ((response: AxiosResponse<T>) => string);
type ToastErrorMessage<T> = ToastContent | ((response: AxiosResponseWithError<T>) => string);
type ToastMessage<T> =
    | ToastContent
    | ((response: AxiosResponse<T> | AxiosResponseWithError<T>) => string);
type ArgumentTypes<T> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>;
    response: T;
};
type IDefaultOptionals<T> = {
    additionalDispatchData?: Record<string, unknown>;
    customErrorMessage?: ToastErrorMessage<T>;
    nonSuccessHook?: ({ dispatch, response }: ArgumentTypes<AxiosResponseWithError<T>>) => void;
    nonSuccessOverride?: ({ dispatch, response }: ArgumentTypes<AxiosResponseWithError<T>>) => void;
    successHook?: ({
        dispatch,
        response,
        getState,
    }: ArgumentTypes<AxiosResponse<T>> & { getState: () => AppStore }) => void;
    successOverride?: ({ dispatch, response }: ArgumentTypes<AxiosResponse<T>>) => void;
    toastAcceptedMessage?: ToastSuccessMessage<T>;
    toastErrorMessage?: ToastErrorMessage<T>;
    toastInitialMessage?: ToastSuccessMessage<T>;
    toastSuccessMessage?: ToastSuccessMessage<T>;
    unconditionalHook?: ({
        dispatch,
        response,
    }: ArgumentTypes<AxiosResponse<T> | AxiosResponseWithError<T>>) => void;
};

const defaultOptionals: IDefaultOptionals<unknown> = {
    additionalDispatchData: {},
    customErrorMessage: '',
    nonSuccessHook: undefined,
    nonSuccessOverride: undefined,
    successHook: undefined,
    successOverride: undefined,
    toastAcceptedMessage: '',
    toastErrorMessage: '',
    toastInitialMessage: '',
    toastSuccessMessage: '',
    unconditionalHook: undefined,
};

const getMessage = <T>(
    message: ToastMessage<T>,
    response: AxiosResponse<T> | AxiosResponseWithError<T>
) => {
    if (typeof message === 'function') {
        message = message(response);
    }
    return message;
};

const getErrorResponseData = <T>(
    response: AxiosResponseWithError<T>,
    key: keyof AxiosResponse<T>
) => response?.error?.response?.[key];

const getErrorMessage = <T>(message: ToastMessage<T>, response: AxiosResponseWithError<T>) =>
    getMessage(message, response) ?? getErrorResponseData(response, 'data') ?? '';

const handleSuccess = <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    getState: () => AppStore,
    optionals: IDefaultOptionals<T>,
    response: AxiosResponse<T>,
    successType: string,
    toastId?: number
) => {
    if (optionals.successOverride) {
        optionals.successOverride({ dispatch, response });
        return;
    }

    dispatch({
        ...optionals.additionalDispatchData,
        data: response.data,
        type: successType,
    });

    const isAccepted = response.status === HTTP_STATUS.ACCEPTED;
    const isSuccess = ([HTTP_STATUS.OK, HTTP_STATUS.CREATED] as number[]).includes(response.status);
    if (
        (isAccepted && optionals.toastAcceptedMessage) ||
        (isSuccess && optionals.toastSuccessMessage)
    ) {
        const message = getMessage(
            isAccepted ? optionals.toastAcceptedMessage : optionals.toastSuccessMessage,
            response
        );

        if (toastId) {
            Toast.update(toastId, { render: message });
        } else {
            Toast.success(message);
        }
    }

    optionals.successHook?.({ dispatch, getState, response });
};

const handleNonSuccess = <T>(
    dispatch: AppStoreThunkDispatch,
    failedType: string,
    optionals: IDefaultOptionals<T>,
    response: AxiosResponse<T> | AxiosResponseWithError<T>,
    toastId: number | undefined
) => {
    if (toastId) {
        Toast.clear(toastId);
    }
    const responseError = response as AxiosResponseWithError<T>;
    if (optionals.nonSuccessOverride) {
        optionals.nonSuccessOverride({
            dispatch,
            response: responseError,
        });
    } else if (axios.isCancel(responseError?.error)) {
        return;
    } else {
        if (optionals.toastErrorMessage) {
            const message = getMessage(optionals.toastErrorMessage, response);
            Toast.error(message);
        }
        dispatch({
            ...optionals.additionalDispatchData,
            errorMessage: getErrorMessage(optionals.customErrorMessage, responseError),
            statusCode: getErrorResponseData(responseError, 'status'),
            statusText: getErrorResponseData(responseError, 'statusText'),
            type: failedType,
        });
        optionals?.nonSuccessHook?.({ dispatch, response: responseError });
    }
};

const commonAction = <T>(
    apiMethod: () => AxiosPromise<T> | Promise<AxiosError<T>>,
    actionType: string,
    optionals: IDefaultOptionals<T> = defaultOptionals
) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (dispatch: Dispatch<any>, getState: () => AppStore) => {
        let toastId: number | undefined;
        if (optionals.toastInitialMessage) {
            toastId = Toast.success(optionals.toastInitialMessage, {
                autoClose: false,
                closeOnClick: false,
            });
        }
        dispatch({
            ...optionals.additionalDispatchData,
            type: actionType.requested,
        });

        const result = await apiMethod();
        let response: AxiosResponse<T> | AxiosResponseWithError<T> = result as AxiosResponse<T>;
        if ((result as AxiosError<T>).response) {
            response = (result as AxiosError<T>).response as AxiosResponseWithError<T>;
        }
        if (
            response.status === HTTP_STATUS.OK ||
            response.status === HTTP_STATUS.CREATED ||
            response.status === HTTP_STATUS.ACCEPTED
        ) {
            handleSuccess(dispatch, getState, optionals, response, actionType.success, toastId);
        } else {
            handleNonSuccess(dispatch, actionType.failed, optionals, response, toastId);
        }
        optionals?.unconditionalHook?.({ dispatch, response });
    };
export default commonAction;
