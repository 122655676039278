import commonAction from 'actions/commonAction';
import HrsApi from 'api/generated/HrsApi';

export const SET_PAYROLL_DEDUCTIONS_FOR_USER_ACTION = 'SET_PAYROLL_DEDUCTIONS_FOR_USER';

export const setPayrollDeductionsForUser = (
    payrollReportId: string | undefined,
    payrollReportUserSnapshotId: string | undefined,
    effectiveDate?: string,
    overridePendingJob?: boolean | undefined
) =>
    commonAction(
        async () =>  new HrsApi().setPayrollDeductionsForUser(
                payrollReportId,
                payrollReportUserSnapshotId,
                effectiveDate,
                overridePendingJob
            ),
        SET_PAYROLL_DEDUCTIONS_FOR_USER_ACTION,
        {
            toastAcceptedMessage: result => result.data.message && result.data.message.trim() !== ''
                ? result.data.message
                : 'There is already a pending deductions job for this user',
            toastErrorMessage: 'Failed to apply withholdings and Wage+ amounts for this user',
            toastSuccessMessage:
                'Successfully applied withholdings and Wage+ amounts for this user',
        }
    );
