import commonAction from 'actions/commonAction';
import PathwayBlueprintApi from 'api/generated/PathwayBlueprintApi';

export const CALCULATE_PATHWAY_USER_ACTION = 'CALCULATE_PATHWAY_USER';

export const calculatePathwayUser = (pathwayUserId: string) =>
    commonAction(
        async () => new PathwayBlueprintApi().calculatePathwayUser(pathwayUserId),
        CALCULATE_PATHWAY_USER_ACTION,
        {
            additionalDispatchData: {
                params: { pathwayUserId },
            },
            toastErrorMessage: 'Failed to recalculate user',
            toastSuccessMessage: 'User successfully recalculated',
        }
    );
