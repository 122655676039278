import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import DynamicFeedOutlined from '@mui/icons-material/DynamicFeedOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FactCheckOutlined from '@mui/icons-material/FileCopyOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MoveToInbox from '@mui/icons-material/MoveToInbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RestoreIcon from '@mui/icons-material/Restore';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { invalidateToken } from 'actions/token/invalidateToken';
import {
    AccessOtherTeams,
    DownloadBulkEnrollmentFile,
    EditTeam,
    ViewPayrollReports,
    ViewSubmittedExpenses,
    ViewTeamBenefit,
    ViewTeamRhFiles,
    ViewTeamSharedPayrollReports,
    ViewTeamSharedReimbursementReports,
    ViewUsers,
} from 'api/generated/permissions';
import Footer from 'components/Footer';
import RhLogo from 'components/authenticatedPageWrapper/appDrawer/RhLogo';
import useExternalTeamProps from 'components/authenticatedPageWrapper/useExternalTeamProps';
import { showNewVersionModal } from 'components/newVersion/newVersionActions';
import { push } from 'connected-react-router';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    BULK_ENROLLMENT_MANAGEMENT_PATH,
    CCM_ENROLLMENT_INFO_PATH,
    DASHBOARD_PATH,
    GLOBAL_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    MY_BENEFITS_PATH,
    PAYROLL_PATH,
    PEOPLE_PATH,
    PROFILE_PATH,
    REIMBURSEMENT_REPORTS_PATH,
    RESOURCES_AND_HELP_PATH,
    RESOURCE_MANAGEMENT_PATH,
    RH_FILES_PATH,
    ROLES_PATH,
    SYS_ROLES_PATH,
    TEAMS_PATH,
    TEAM_BENEFITS_MANAGEMENT_PATH,
    TEAM_BENEFITS_PATH,
    TEAM_PROFILE_PATH,
    USER_RH_FILES_PATH,
    VERIFICATION_DOCUMENTS_PATH,
} from 'routers/routes';
import { TokenService } from 'security/TokenService';
import { hasSomePermissions } from 'selectors';
import { isCcmAdminSelector, isRhFullAdminSelector, isRhSelector } from 'selectors/role';
import { isFalse } from 'utilities';

const AppDrawerContents = ({
    setIsDrawerOpen,
}: {
    setIsDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const dispatch = useThunkDispatch();
    const { useReimbursementProgramManagement } = useTeamProps();
    const location = useLocation();
    const { team } = useExternalTeamProps();
    const enableMyBenefits = team?.isCustomer;
    const {
        accessToken,
        enableBulkEnrollmentManagement,
        enableEnrollmentInfo,
        enablePeople,
        enableReimbursementExpenseManagement,
        enableResourceManagement,
        enableRhFiles,
        enableRoles,
        enableSystemRoles,
        enableTeamBenefits,
        enableTeamBenefitsManagement,
        enableTeamPayroll,
        enableTeamProfile,
        enableTeamReimbursementReports,
        enableTeams,
        refreshToken,
        willUpdateLater,
    } = useSelector((state: AppStore) => ({
        accessToken: state.login.accessToken,
        enableBulkEnrollmentManagement: hasSomePermissions(state, DownloadBulkEnrollmentFile),
        enableEnrollmentInfo: isCcmAdminSelector(state),
        enablePeople: hasSomePermissions(state, ViewUsers),
        enableReimbursementExpenseManagement:
            hasSomePermissions(state, ViewSubmittedExpenses) &&
            hasSomePermissions(state, AccessOtherTeams),
        enableResourceManagement: isRhSelector(state),
        enableRhFiles: hasSomePermissions(state, ViewTeamRhFiles),
        enableRoles: isRhFullAdminSelector(state),
        enableSystemRoles: isRhFullAdminSelector(state),
        enableTeamBenefits: hasSomePermissions(state, ViewTeamBenefit),
        enableTeamBenefitsManagement: isRhFullAdminSelector(state),
        enableTeamPayroll:
            hasSomePermissions(state, ViewPayrollReports) ||
            (team?.payrollReportSharingEnabled &&
                hasSomePermissions(state, ViewTeamSharedPayrollReports)),
        enableTeamProfile: hasSomePermissions(state, EditTeam),
        enableTeamReimbursementReports:
            hasSomePermissions(state, ViewTeamSharedReimbursementReports) &&
            !hasSomePermissions(state, AccessOtherTeams) &&
            useReimbursementProgramManagement,
        enableTeams: hasSomePermissions(state, AccessOtherTeams),
        refreshToken: state.login.refreshToken,
        willUpdateLater: state.newVersionState.willUpdateLater,
    }));
    const onInvalidateTokenClick = useCallback(
        (shouldRestoreExistingToken: boolean) => (e: React.MouseEvent) => {
            e.preventDefault();
            dispatch(invalidateToken(accessToken, refreshToken, shouldRestoreExistingToken));
        },
        [accessToken, dispatch, refreshToken]
    );

    const sections: {
        dataCy?: string;
        icon: ReactNode;
        isSelected?: boolean;
        isVisible?: boolean;
        label: string;
        onClick?: React.MouseEventHandler;
        toPath?: string;
    }[][] = useMemo(
        () => [
            [
                {
                    icon: <RefreshIcon />,
                    isVisible: willUpdateLater,
                    label: 'Update',
                    onClick: () => dispatch(showNewVersionModal()),
                },
            ],
            [
                {
                    dataCy: 'nav-dashboard',
                    icon: <HomeOutlinedIcon />,
                    label: 'Dashboard',
                    toPath: DASHBOARD_PATH,
                },
                {
                    dataCy: 'nav-my-benefits',
                    icon: <MedicalServicesOutlinedIcon />,
                    isVisible: enableMyBenefits,
                    label: 'My Benefits',
                    toPath: MY_BENEFITS_PATH,
                },
                {
                    dataCy: 'nav-profile',
                    icon: <BadgeOutlinedIcon />,
                    label: 'My Profile',
                    toPath: PROFILE_PATH,
                },
                {
                    icon: <InsertDriveFileOutlinedIcon />,
                    label: 'My Files',
                    toPath: USER_RH_FILES_PATH,
                },
                {
                    icon: <SummarizeOutlinedIcon />,
                    label: 'My Verification Documents',
                    toPath: VERIFICATION_DOCUMENTS_PATH,
                },
            ],
            [
                {
                    dataCy: 'nav-teams',
                    icon: <SportsBasketballOutlinedIcon />,
                    isVisible: enableTeams,
                    label: 'Teams',
                    toPath: TEAMS_PATH,
                },
                {
                    icon: <InfoOutlinedIcon />,
                    isVisible: enableTeamProfile,
                    label: 'Team Profile',
                    toPath: TEAM_PROFILE_PATH,
                },
                {
                    icon: <GroupOutlinedIcon />,
                    isVisible: enablePeople,
                    label: 'Team Members',
                    toPath: PEOPLE_PATH,
                },
                {
                    dataCy: 'nav-team-benefits',
                    icon: <CasesOutlinedIcon />,
                    isVisible: enableTeamBenefits,
                    label: 'Team Benefits',
                    toPath: TEAM_BENEFITS_PATH,
                },
                {
                    icon: <FileCopyOutlinedIcon />,
                    isVisible: enableRhFiles,
                    label: 'Team Files',
                    toPath: RH_FILES_PATH,
                },
                {
                    dataCy: 'nav-team-payroll',
                    icon: <AccountBalanceIcon />,
                    isVisible: enableTeamPayroll,
                    label: 'Team Payroll',
                    toPath: PAYROLL_PATH,
                },
                {
                    icon: <RequestPageIcon />,
                    isVisible: enableTeamReimbursementReports,
                    label: 'Team Reimbursement Reports',
                    toPath: REIMBURSEMENT_REPORTS_PATH,
                },
            ],
            [
                {
                    icon: <RequestPageIcon />,
                    isVisible: enableReimbursementExpenseManagement,
                    label: 'Reimbursement Expense Management',
                    toPath: GLOBAL_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
                },
                {
                    icon: <MoveToInbox />,
                    isVisible: enableBulkEnrollmentManagement,
                    label: 'Bulk Enrollment Management',
                    toPath: BULK_ENROLLMENT_MANAGEMENT_PATH,
                },
                {
                    icon: <DynamicFeedOutlined />,
                    isVisible: enableTeamBenefitsManagement,
                    label: 'Team Benefits Management',
                    toPath: TEAM_BENEFITS_MANAGEMENT_PATH,
                },
                {
                    icon: <FactCheckOutlined />,
                    isVisible: enableEnrollmentInfo,
                    label: 'Enrollment Info',
                    toPath: CCM_ENROLLMENT_INFO_PATH,
                },
                {
                    icon: <AdminPanelSettingsOutlined />,
                    isVisible: enableRoles,
                    label: 'Member Role Management',
                    toPath: ROLES_PATH,
                },
                {
                    icon: <LockOutlined />,
                    isVisible: enableSystemRoles,
                    label: 'Role Management',
                    toPath: SYS_ROLES_PATH,
                },
                {
                    icon: <LanguageOutlined />,
                    isVisible: enableResourceManagement,
                    label: 'Resource Management',
                    toPath: RESOURCE_MANAGEMENT_PATH,
                },
                {
                    icon: <HelpOutlineOutlined />,
                    label: 'Resources and Help',
                    toPath: RESOURCES_AND_HELP_PATH,
                },
                {
                    dataCy: 'restore-session',
                    icon: <RestoreIcon />,
                    isVisible: TokenService.getHasExistingToken(),
                    label: 'Restore Session',
                    onClick: onInvalidateTokenClick(true),
                },
                {
                    dataCy: 'logout',
                    icon: <LogoutOutlined />,
                    label: 'Logout',
                    onClick: onInvalidateTokenClick(false),
                },
            ],
        ],
        [
            dispatch,
            enableBulkEnrollmentManagement,
            enableEnrollmentInfo,
            enableMyBenefits,
            enablePeople,
            enableReimbursementExpenseManagement,
            enableResourceManagement,
            enableRhFiles,
            enableRoles,
            enableSystemRoles,
            enableTeamBenefits,
            enableTeamBenefitsManagement,
            enableTeamPayroll,
            enableTeamProfile,
            enableTeams,
            onInvalidateTokenClick,
            enableTeamReimbursementReports,
            willUpdateLater,
        ]
    );
    const sectionsToRender = sections.map((items) =>
        items.filter((item) => !isFalse(item.isVisible))
    );

    const onListItemClick = (
        onClick: React.MouseEventHandler<HTMLDivElement> | undefined,
        toPath: string | undefined
    ) => (e: React.MouseEvent<HTMLDivElement>) => {
        if (toPath) {
            dispatch(push(toPath));
        } else {
            onClick?.(e);
        }
        setIsDrawerOpen?.(false);
    };

    const showDividers = enablePeople;

    return (
        <Stack height={{ sm: 'auto', xs: '100vh' }} justifyContent="space-between">
            <Stack>
                <RhLogo />
                {sectionsToRender.map((items, index) => (
                    <React.Fragment key={index}>
                        {items.length > 0 && (
                            <React.Fragment>
                                <List disablePadding={!showDividers}>
                                    {items.map(
                                        ({ dataCy, icon, isSelected, label, onClick, toPath }) => (
                                            <ListItemButton
                                                data-cy={dataCy}
                                                key={label}
                                                onClick={onListItemClick(onClick, toPath)}
                                                sx={[
                                                    {
                                                        color: 'white',
                                                    },
                                                    (toPath !== undefined &&
                                                        location.pathname.split('/')[1] ===
                                                            toPath.split('/')[1]) ||
                                                    isSelected
                                                        ? {
                                                              backgroundColor:
                                                                  'rgba(255,255,255,0.15)',
                                                              fontStyle: 'italic',
                                                          }
                                                        : false,
                                                ]}
                                            >
                                                <ListItemIcon sx={{ color: 'white' }}>
                                                    {icon}
                                                </ListItemIcon>
                                                <ListItemText primary={label} />
                                            </ListItemButton>
                                        )
                                    )}
                                </List>
                                {index !== sectionsToRender.length - 1 && showDividers && (
                                    <Divider sx={{ borderColor: 'rgba(255,255,255,.5)' }} />
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ))}
            </Stack>
            <Divider sx={{ marginBlock: 3 }} />
            <Footer />
        </Stack>
    );
};

export default hot(module)(AppDrawerContents);
