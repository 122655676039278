import commonAction from 'actions/commonAction';
import { ISelectedPlan, SelectedPlan } from 'api/generated/models';
import SelectedPlansApi from 'api/generated/SelectedPlansApi';

export const ADD_CUSTOM_SELECTED_PLANS_ACTION = 'ADD_CUSTOM_SELECTED_PLANS';

export const addCustomSelectedPlans = (
    selectedPlans: Partial<ISelectedPlan>[],
    toastSuccessMessage?: string
) =>
    commonAction(
        async () =>
            new SelectedPlansApi().addCustom(
                selectedPlans.map((x) => new SelectedPlan(x as ISelectedPlan))
            ),
        ADD_CUSTOM_SELECTED_PLANS_ACTION,
        {
            toastSuccessMessage,
            toastErrorMessage: (response) =>
                `Unable to save custom plan. ${response.error.response?.data}`,
        }
    );
