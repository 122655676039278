import { IDrug, IProvider } from 'api/generated/models';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import titleCase from 'titleize';
import { hasValue } from 'utilities/index';

type ICommonNotesInfoProps = {
    EditNotesModal: (close: () => void) => JSX.Element;
    data: (IDrug | IProvider)[];
    label: string;
    readonly: boolean;
};

const CommonNotesInfo = ({ data, EditNotesModal, label, readonly }: ICommonNotesInfoProps) => {
    const [isEditNotesModalVisible, setIsEditNotesModalVisible] = useState(false);
    const hideModal = useCallback(() => setIsEditNotesModalVisible(false), []);
    const showModal = useCallback(() => setIsEditNotesModalVisible(true), []);
    let content: JSX.Element[] | string | undefined = readonly ? 'None Added' : undefined;
    let textToCopy: string | undefined = '';
    if (data?.length > 0) {
        content = data.map((x, index) => {
            textToCopy += index < data.length - 1 ? `${x.name}\r\n` : `${x.name}`;
            const key = (x as IDrug).drugId ?? (x as IProvider).providerId;
            const { route, strength } = x as IDrug;
            const dosage = hasValue(strength) || hasValue(route) ? ` - ${strength} ${route}` : '';
            return (
                <div key={key}>
                    {titleCase(x.name as string)}
                    {dosage}
                </div>
            );
        });
    }

    return (
        <React.Fragment>
            {isEditNotesModalVisible && EditNotesModal(hideModal)}
            <EditableProfileAttribute
                content={content}
                label={label}
                onClick={showModal}
                readonly={readonly}
                textToCopy={textToCopy ?? ''}
            />
        </React.Fragment>
    );
};
export default hot(module)(CommonNotesInfo);
